/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {ErrorMessage, Field, FieldArray, Form,  Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 

import { api_add, FileUpload, get_data, user_add, get_data_json } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { FormSelect } from './FormSelect'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from './FileUploadServices'
import { FormObject } from './FormObject'
import { FormTabs } from './FormTabs'
import { interval } from 'rxjs'
import { Panel2 } from './AddSurveys'
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[],
  tickets:[],

}


const EditOrderSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseData:any;
  // Records per page to be shown & loaded
  id: number;


}

export const EditOrder = ({
  apiRoute,
  responseData,
  id ,

}: Props) => {
// class EditOrder extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  const [showForm, setshowForm] = useState(false);
  
 

  const [value_select, SetValueSelect] = useState([] as any);
 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: EditOrderSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:'', 
          recipient_id:''
        }
        setShowAlert(false)
        
      
        api_add( 'sam_role_update_order/'+params.type+'/'+id, sat)
          .then((data:any)  => {
            

            if(data.data.status)
            {

              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
              setLoading(false)
              responseData(false);
            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)
              setLoading(false)


            }
    

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('EditOrder process has broken')
          })
      }, 1000)
    },
  });
  const load_data = () => {
  
    if(id)
    {
 
     get_data('sam_role_edit/'+ params.type +'?id='+id)
     .then(data => {
       
       
       setModel(data.data.data);
       initialValues=data.data.data;
   formik.setValues(initialValues);
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
  
    get_data('resp_order/'+params.type+'/'+id
    //  get_data('org_structure'
     )
      .then(data => {
        SetValueSelect(data.data.data)
         
        // dispatch(auth.actions.login(accessToken))
      })
      .catch(() => {
      
     
      })
      
  
      
  }

  //////////////              array
  
 
  const [AddValue, setAddValue] = useState([] as any);
  const [AddValueEdit, setAddValueEdit] = useState([]);
  
  const [countData, setcountData] = useState(0); 
 
  const initialValues7 = {
    numberOfTickets: '',
    tickets: []
};
   
const validationSchema7 = Yup.object().shape({
     
  
});
const onSubmit7 =  (value_array:any) => {
  // display form field values on success

  
  setLoading(true)
  setTimeout(() => {
    let values=formik.values;
    values.sam_chiled=[];
    
    let sat={
      data:JSON.stringify(values),
      AddedDate:AddedDate,
      father_key:'',
      data_object:'', 
      recipient_id:''
    }
    setShowAlert(false)
    
  
    api_add( 'sam_role_update_order/'+params.type+'/'+id, sat)
      .then((data:any)  => {
        

        if(data.data.status)
        {

          setStatus_code('success')
          setStatus_massag('تمت العملية بنجاح')
          setShowAlert(true)
          setLoading(false)
          responseData(false);
        }else
        {
          setStatus_code('danger')
          setStatus_massag(data.data.message)
          setShowAlert(true)
          setLoading(false)


        }


        // dispatch(auth.actions.login(accessToken))
      })
      .catch(() => {
        setLoading(false)
      
      })
  }, 1000)
}
const handleLgCloseObject = (event: any) =>
{

  if(event.data)
  {
    AddValue[event.id]=event
    let res={
      id:id, 
      role_type_id:id,
      sam:'array',
  
      data:JSON.stringify(AddValue),
      AddedDate:AddedDate,
    } 

  }
  

 
} 
 

const handleLgAdd = (e: any, values: any, setValues: any)=>
 {
  // update dynamic form
   
  const tickets = [...values.tickets];
  tickets.push({p_key:0,filed1:'',filed2:'',filed3:''});
   
  setValues({ ...values, tickets });
 
}
const handleLgAdd_array = (e: any, values: any, setValues: any)=>
 {
  // update dynamic form
   
  if(values.tickets.length==0&&AddValueEdit.length>0)
  {
    const tickets = [...values.tickets];
    AddValueEdit.forEach(element => {
    tickets.push(element);
        
      });
  
    setValues({ ...values, tickets });
  }

 
}
  useEffect(() => {
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
 
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
  
       setLoading(false)
      load_data();

       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
   
  useEffect(() => {

 
   }, []);
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{Surveys?.title_root} 
            {/* { params.id } */}
         
            </span>
          <span className='card-label fw-bolder fs-3 mb-1'>    تعديل  بيانات </span> 

            <br/>
            {/* { params.id } */}
           
           </h3>
          <div className='card-toolbar'>
         
       
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>

          {showAlert && (
          
          <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
          <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
          <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
            <h5 className="mb-1">This is an alert</h5>
            <span>{status_massage}.</span>
          </div>
        
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
          </button>
        </div>
         
                )}

            {/* begin::Table */}
       
            <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
      
        {/* end::Heading */}
  
        {/* begin::Action */}
      
        {/* end::Action */}
 
  
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
  
    
      {/* begin::Form group Firstname */}
  
      <div className='row fv-row mb-7'>
  
{dataList && dataList.map((plan:any, index:number) => {
                return (
               
          <div className='row fv-row mb-7'>

      <Panel2 data_ob={plan} data_index={index} formik={formik}
      value_object={initialValues} />


<div   className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'>  </label>
  <input
        placeholder='info'
        type='text' 
        autoComplete='off'
        {...formik.getFieldProps(plan.status_type+'_info')}
      
        className={clsx(
          'form-control form-control-lg form-control-solid'
        )}
      />
   
  </div>
 
 

  {value_select && value_select.length>0 &&(
  <div className='col-xl-12'> 
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>سبب تعديل {plan?.title}
            
            </label>
         
             <select
        placeholder={'_type_id'}
        multiple={false}
        required={true}
     
        {...formik.getFieldProps(plan.status_type+'_type_id')}

        className={clsx(
          'form-control form-control-lg form-control-solid'
        )}
      > 
             {/* <option value="0">Select value</option> */}
 
          
 {value_select && value_select.map((plan_e:any, index:number) => {
             return (

               <option key={index} value={plan_e.id} >{plan_e.title}</option>

               );
             })} 
      </select>
 

          
          </div> 
        </div>
)}

  </div>

                  );
                })} 
          


  
</div>
 

        {/* end::Form group */}
   
      
        {/* end::Form group */}
  
        {/* begin::Form group */}
        <div className='text-left'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            disabled={!formik.isValid  }
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
              onClick={(e) => responseData(false)}

            >
              الغاء
            </button>
         
        </div>
        {/* end::Form group */}
      </form>
        
            {/* end::Table */}
            {/* {paginationBasic} */}
    
         {/* begin::Table */}
         {showForm && (
          <Formik initialValues={initialValues7} validationSchema={validationSchema7} onSubmit={onSubmit7}>
            {({ errors, values, touched, setValues }) => (
              
                <Form>
                   
                 
               
            <a   className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={e => handleLgAdd_array(e, values, setValues)} 
            >
                <KTSVG path='/media/icons/duotune/art/art009.svg' className='svg-icon-3' />

                  </a>

              {/* {AddValueEdit && (
                    values.tickets=AddValueEdit
                  )} */}
                    <div className="card m-3" >
                        <h5 className="card-header">{Surveys?.title_root} </h5>
                       
                        <FieldArray name="tickets">
                        {() => ( dataList &&  dataList.map((plan:any, i:number) => {
                            const ticketErrors = errors.tickets?.length && errors.tickets[i]  ;
                            const ticketTouched = touched.tickets?.length && touched.tickets[i]  ;
                            return (
                                <div key={i} className="row fv-row mb-7">

               {/* <div className='col-xl-2'>
  
               <Field name={`ticket.${i}.name`} />

   



</div> */}
         <Panel2  data_ob={plan} data_index={i} formik={formik}
                                           value_object={initialValues}  />
<div   className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'>  </label>

      <Field name={`ticket.${i}.name`}
        placeholder='title'
      
      className={clsx(
        'form-control form-control-lg form-control-solid'
      )} />

   
  </div>
 
  <div   className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'>  </label>

      <Field name={`ticket.${i}.title`}
        placeholder='info'
      
      className={clsx(
        'form-control form-control-lg form-control-solid'
      )} />

   
  </div>
  

         
            
  
  
    
  {/* </div> */}
                                </div>
                            );
                        }))}
                        </FieldArray>
                        <div className="buttons">
            <button type="button" 
            className="btn btn-success mr-1"
            onClick={e => handleLgAdd(e, values, setValues)} 
            >

              Add new
            </button>
   
          </div>
                        {/* <div className="card-footer text-center border-top-0">
                            <button type="button" 
                              onClick={e => onSubmit7(values)} className="btn btn-primary mr-1">

                                Buy Tickets
                            </button>
                            <button className="btn btn-secondary mr-1" type="reset">Reset</button>
                        </div> */}
                    </div>
                    
<div className='text-left'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
              onClick={(e) => responseData(false)}

            >
              الغاء
            </button>
         
        </div>
                </Form>
                
            )}

            
        </Formik>
           )}
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default EditOrder;