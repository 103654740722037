/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {ErrorMessage, Field, FieldArray, Form,  Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 

import { api_add, FileUpload, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { FormSelect } from './FormSelect'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from './FileUploadServices'
import { FormObject } from './FormObject'
import { FormTabs } from './FormTabs'
interface Person {
  id: string;
  firstName: string;
  lastName: string;
}
const initialValues = {
 
  filed2: '',
  filed1: '',
  filed3: '',
  filed4: '',
  sam_chiled:[],
  tickets: [{name:'sam',email:'sam@admin.com'}]
}
 
const addSurveysSchema = Yup.object().shape({
  
  tickets: Yup.array().of(
    Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    })
)
 
})

export interface IPanelProps {
  txt?: string;
  data_ob: any;
  formik: any;
  data_index: number;
  

}
export interface IPanelDemoProps {
  panelName: string;
  data_ob: any;
}

export class PanelDemo2 extends Component<IPanelDemoProps, {}> {
  selectPanel = (panelName: string,data_ob:any) => {
    switch (panelName) {
      case "panel1":
        return <Panel2 data_ob={data_ob} data_index={1} formik={2}/>;

      case "panel2":
        return <Panel2 data_ob={data_ob} data_index={1} formik={2}/>;

      case "panel3":
        return <Panel2 data_ob={data_ob} data_index={1} formik={2}/>;

      default:
        return <div>Unknown!</div>;
    }
  };
  render() {
    const { panelName } = this.props;
    const { data_ob } = this.props;
    return this.selectPanel(panelName,data_ob);
  }
}

export class Panel2 extends Component<IPanelProps, {}> {
  value_select: any[]=[];
  
  render() {
    
 

    const { txt } = this.props;
    const { data_ob } = this.props;
    const { data_index } = this.props;
    const { formik } = this.props;
    
    // this.value_select=[];

    const load_data = () => {

     
      
      
      
    }
    const handleLgClose = (event: any) =>
    {
    
      
      formik.setFieldValue(event.key,event.value);
    } 
    // react-hooks/rules-of-hooks
    
  
    if (data_ob.ztype=='textfield')
    {
      return    <div  key={data_index} className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='text'
        required={data_ob.zform}
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
      
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched[data_ob.status_type] && formik.errors[data_ob.status_type],
          },
          {
            'is-valid': formik.touched[data_ob.status_type] && !formik.errors[data_ob.status_type],
          }
        )}
      />
             {formik.touched[data_ob.status_type] && formik.errors[data_ob.status_type] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors[data_ob.status_type]}</span>
              </div>
            </div>
          )}
 
    </div>
    }else if (data_ob.ztype=='text')
    {
      return    <div  key={data_index} className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <textarea
        placeholder={data_ob.title}
        value={formik.getFieldProps(data_ob.status_type).value}
     
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      >
       {formik.getFieldProps(data_ob.status_type).value}
      </textarea>
 
    </div>
    }else  if (data_ob.ztype=='number')
    {
      return  <div  key={data_ob} className='col-xl-6'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='file')
    {
      
    
      return  <FileUploadServices key={data_index} apiRoute={`sam`}
      responseData={handleLgClose}
      id={1}
      data_ob={data_ob}
      data_survey={data_ob}
      data_index={8} />

    }else  if (data_ob.ztype=='date')
    {
      return   <div  key={data_ob} className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='date' 
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
 
    </div>
    }else  if (data_ob.ztype=='time')
    {
      return   <div  key={data_ob} className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='time' 
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
 
    </div>
    }else if (data_ob.ztype=='select')
    {


      return  <FormSelect key={data_index} apiRoute={`sam`}
      response7Data={handleLgClose}
      id={1}
      data_ob={data_ob}
      data_survey={data_ob}
      data_index={8} 
      formik={formik}/>
 
    }else
    {
      return <div></div>
    }
    
  }
}

export function AddTestSurveys() {
  const d=useHistory();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const params = useParams<any>();
  const [Surveys, setSurveys] = useState({} as any);
  const [SurveyChildren, setSurveyChildren] = useState([] as any);
  
  const [dataList, setList] = useState([] as any);
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
  const toggleShowAlert = () => setShowAlert(!showAlert);
  
  useEffect(() => {
    if(params.type )
    {
 
     get_data('sam_role_add/'+params.type )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
      setSurveyChildren(data.data.children)
  
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
   const [people, setPeople] = useState<Person[]>([
    { id: "5", firstName: "one", lastName: "two" }
  ]);
   const AddedDate=new Date().valueOf(); 
 

  const initialValues7 = {
    numberOfTickets: '',
    tickets: []
};

const validationSchema7 = Yup.object().shape({
    numberOfTickets: Yup.string()
        .required('Number of tickets is required'),
    tickets: Yup.array().of(
        Yup.object().shape({
            name: Yup.string()
                .required('Name is required'),
            email: Yup.string()
                .email('Email is invalid')
                .required('Email is required')
        })
    )
});
function onSubmit7(fields:any) {
  // display form field values on success
  alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
}
const handleLgClose = (event: any) =>
{

  
 
} 
const handleFormTabs = (event: any) =>
{

  
 
} 

const handleLgAdd = (e: any, values: any, setValues: any)=>
 {
  // update dynamic form
  const tickets = [...values.tickets];
 
  tickets.push({ name: '', email: '' });

  setValues({ ...values, tickets });
 
}

function onChangeTickets(e: any, field:any, values: any, setValues: any) {
  // update dynamic form
  const tickets = [...values.tickets];
  const numberOfTickets = e.target.value || 0;
  const previousNumber = parseInt(field.value || '0');
  if (previousNumber < numberOfTickets) {
      for (let i = previousNumber; i < numberOfTickets; i++) {
          tickets.push({ name: '', email: '' });
      }
  } else {
      for (let i = previousNumber; i >= numberOfTickets; i--) {
          tickets.splice(i, 1);
      }
  }
  setValues({ ...values, tickets });

  // call formik onChange method
  field.onChange(e);
}

  return (
   
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'> {Surveys?.title_root} </span> 
         
       
          <span className='card-label fw-bolder fs-3 mb-1'> test   اضافة جديد </span> 
 
        </h3>
        <div className='card-toolbar'>
       
          <Link to={"/crafted/surveys/dashboard/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </Link>
          {/* <Link to="add">new </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
       
        {showAlert && (
          
  <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
  <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
  <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
    <h5 className="mb-1">This is an alert</h5>
    <span>{status_massage}.</span>
  </div>

  <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
    <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
  </button>
</div>



        )}
     

     

     <FormTabs   apiRoute={`sam`} id={1}  dataList={SurveyChildren}
                responseDataTabs={handleFormTabs}/>

          {/* begin::Table */}
          <Formik initialValues={initialValues7} validationSchema={validationSchema7} onSubmit={onSubmit7}>
            {({ errors, values, touched, setValues }) => (
                <Form>
                    <div className="card m-3">
                        <h5 className="card-header">React + Formik Dynamic Form Example</h5>
                       
                        <FieldArray name="tickets">
                        {() => (values.tickets.map((ticket, i) => {
                            const ticketErrors = errors.tickets?.length && errors.tickets[i] || {name:'',email:''};
                            const ticketTouched = touched.tickets?.length && touched.tickets[i] || {name:'',email:''};
                            return (
                                <div key={i} className="list-group list-group-flush">
                                      <FormObject key={i} apiRoute={`sam`} id={1}  dataList={dataList}
                responseDataObject={handleLgClose} ticket={ticket} />
                                
                                </div>
                            );
                        }))}
                        </FieldArray>
                        <div className="buttons">
            <button type="button" 
            onClick={e => handleLgAdd(e, values, setValues)} 
            >

          
            </button>
   
          </div>
                        <div className="card-footer text-center border-top-0">
                            <button type="submit" className="btn btn-primary mr-1">
                                Buy Tickets
                            </button>
                            <button className="btn btn-secondary mr-1" type="reset">Reset</button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
   
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}
    </div>

  )
}
