/* eslint-disable jsx-a11y/anchor-is-valid */ 
import React, {useEffect, useState,useRef} from 'react'

import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
 
import { api_add, api_put, get_data, user_add } from '../api'

import { useDispatch } from 'react-redux'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}

const MixedWidget2Sam: React.FC<Props> = ({className, chartColor, chartHeight, strokeColor}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  
  const [count_, Setcount_] = useState(0);
  const [count_2_1, Setcount_2] = useState(0);
  const [count_3_1, Setcount_3] = useState(0);
  const [count_4, Setcount_4] = useState(0);

  const [count_2_2, Setcount_2_2] = useState(0);
  const [count_3_2, Setcount_3_2] = useState(0);

  const [count_2_3, Setcount_2_3] = useState(0);
  const [count_3_3, Setcount_3_3] = useState(0);

  
  const [count_Credit, Setcount_Credit] = useState(0);
  const [count_Debit, Setcount_Debit] = useState(0);
  
  const [count_Credit2, Setcount_Credit2] = useState(0);
  const [count_Debit2, Setcount_Debit2] = useState(0);
  

  const [count_Credit3, Setcount_Credit3] = useState(0);
  const [count_Debit3, Setcount_Debit3] = useState(0);
  



  const [menu, setMenu] = useState([] as any);
  const [model, setModel] = useState({} as any)
  const [dataListView, setListView] = useState([] as any);
  const dispatch = useDispatch()

  const logout = () => {
    dispatch(auth.actions.logout())
  }
  useEffect(() => {
    get_data('report_today?skip=0&top=20')
    .then(data => {
       
      // logout();
          
        Setcount_(data.data.count_);
        Setcount_2(data.data.count_2);
        Setcount_3(data.data.count_3);

        Setcount_2_2(data.data.count_2_2);
        Setcount_3_2(data.data.count_3_2);

        Setcount_2_3(data.data.count_2_3);
        Setcount_3_3(data.data.count_3_3);

        




        Setcount_4(data.data.count_4);
        Setcount_Credit(data.data.Credit_total);
        Setcount_Credit2(data.data.Credit_total2);
        Setcount_Credit3(data.data.Credit_total3);
        Setcount_Debit(data.data.Debit_total);
        Setcount_Debit2(data.data.Debit_total2);
        Setcount_Debit3(data.data.Debit_total3);
        
        
        
        if(data.data.status=="Token is Expired")
        {
          logout();
          
        }
         

       debugger

      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
   
 

   
  }, [])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
<>
<div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className='card-title fw-bolder text-white'>احصائبات</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7'>
            <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-3x svg-icon-danger d-block my-2'
              />
              <a href='#' className='text-warning fw-bold fs-6'>
              اجمالي دائن ريال يمني
              

              </a>
              <br/>
              {count_Credit}

            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
            <KTSVG
                path='/media/icons/duotune/communication/com010.svg'
                className='svg-icon-3x svg-icon-success d-block my-2'
              />
              <a href='#' className='text-primary fw-bold fs-6'>
              اجمالي مدين ريال يمني
              </a>
              <br/>


              {count_Debit}

            </div>
            {/* end::Col */}
          </div>

          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7'>
              <KTSVG
                path='/media/icons/duotune/general/gen032.svg'
                className='svg-icon-3x svg-icon-warning d-block my-2'
              />
              <a href='#' className='text-warning fw-bold fs-6'>
              اجمالي دائن دولار


              </a>
              <br/>
              {count_Credit2}

            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr075.svg'
                className='svg-icon-3x svg-icon-primary d-block my-2'
              />
              <a href='#' className='text-primary fw-bold fs-6'>
              
              اجمالي مدين دولار

              </a>
              <br/>


              {count_Debit2}

            </div>
            {/* end::Col */}
          </div>
          

          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col bg-light-danger px-6 py-8 rounded-2 me-7 mb-7'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-3x svg-icon-danger d-block my-2'
              />
              <a href='#' className='text-danger fw-bold fs-6 mt-2'>
              اجمالي دائن سعودي
              </a>
              <br/> 
              {count_Credit3}

            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-success px-6 py-8 rounded-2 mb-7'>
              <KTSVG
                path='/media/icons/duotune/communication/com010.svg'
                className='svg-icon-3x svg-icon-success d-block my-2'
              />
              <a href='#' className='text-success fw-bold fs-6 mt-2'>
              اجمالي مدين سعودي




              </a>
              <br/>

             {count_Debit3}

            </div>
            {/* end::Col */}
          </div>
          <div className='row g-0'>
            {/* begin::Col */}

            <div className='col bg-light-danger px-6 py-8 rounded-2   me-7 mb-7'>
 
              <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-3x svg-icon-danger d-block my-2'
              /> 
              <a href='#' className='text-danger fw-bold fs-6 mt-2'>
              اجمالي الميبعات يمني
              </a>
              <br/> 
              {count_2_1}

            </div>

         
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr075.svg'
                className='svg-icon-3x svg-icon-primary d-block my-2'
              />
              <a href='#' className='text-primary fw-bold fs-6'>
              اجمالي المشتريات يمني
              </a>
              <br/> 
              {count_3_1}

            </div>
            {/* end::Col */}
          </div>
          

          <div className='row g-0'>
            {/* begin::Col */}

            <div className='col bg-light-danger px-6 py-8 rounded-2   me-7 mb-7'>

             
              <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-3x svg-icon-danger d-block my-2'
              />
 
              <a href='#' className='text-danger fw-bold fs-6 mt-2'>
             اجمالي الميبعات دولار


              </a>
              <br/>


              {count_2_2}

            </div>

         
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr075.svg'
                className='svg-icon-3x svg-icon-primary d-block my-2'
              />
              <a href='#' className='text-primary fw-bold fs-6'>
       اجمالي المشتريات دولار
              </a>
              <br/>


              {count_3_2}

            </div>
            {/* end::Col */}
          </div>

          <div className='row g-0'>
            {/* begin::Col */}

            <div className='col bg-light-danger px-6 py-8 rounded-2   me-7 mb-7'>

             
              <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-3x svg-icon-danger d-block my-2'
              />
 
              <a href='#' className='text-danger fw-bold fs-6 mt-2'>
              اجمالي الميبعات سعودي


              </a>
              <br/>


              {count_2_3}

            </div>

         
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr075.svg'
                className='svg-icon-3x svg-icon-primary d-block my-2'
              />
              <a href='#' className='text-primary fw-bold fs-6'>
              اجمالي  المشتريات سعودي
              </a>
              <br/>


              {count_3_3}

            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7'>
              <KTSVG
                path='/media/icons/duotune/general/gen032.svg'
                className='svg-icon-3x svg-icon-warning d-block my-2'
              />
              <a href='#' className='text-warning fw-bold fs-6'>
              اجمالي الميبعات اليوم

              </a>
              <br/>
              {count_}

            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-success px-6 py-8 rounded-2 mb-7'>
              <KTSVG
                path='/media/icons/duotune/communication/com010.svg'
                className='svg-icon-3x svg-icon-success d-block my-2'
              />
              <a href='#' className='text-success fw-bold fs-6 mt-2'>
             اجمالي الاصناف
 
              </a>
              <br/>

             {count_4}

            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
    </>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const color = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export {MixedWidget2Sam}
