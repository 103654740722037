/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState,useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams,useHistory} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add,get_data_json } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { FormTabs } from './FormTabs'
import ReactToPrint from 'react-to-print'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
 
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}

const InfoSurveysSchema = Yup.object().shape({
 
 
})

export function InfoSurveys() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const d=useHistory();
  
  const params = useParams<any>();
 
 

    
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
 
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
  const [SurveyChildren, setSurveyChildren] = useState([] as any);
  const [DataChildren, setDataChildren] = useState([] as any);
  const [Data, setData] = useState([] as any);
  const [dataListView, setdataListView] = useState([] as any);

  const componentRef=useRef(null as any);
  

  const [info_user_name, setdatainfo_user_name] = useState('sam');
  const [info_user_date, setdatainfo_user_date] = useState(new Date().toLocaleTimeString() +' '+ new Date().toLocaleDateString());
  
  


  const [AddValueTabs, setAddValue] = useState({} as any);
  

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: InfoSurveysSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:AddValueTabs, 
          recipient_id:''
        }
        setShowAlert(false)
    
        api_add( 'sam_role_update/'+params.type+'/'+params.id, sat)
          .then((data:any)  => {
          
            if(data.data.status)
            { 
              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
              setLoading(false) 
        d.goBack()
            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)
              setLoading(false) 
            } 
            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('info process has broken')
          })
      }, 1000)
    },
  });
  const load_data = () => {
  
    if(params.id)
    {
 
     get_data('sam_role_info/'+ params.type +'?id='+params.id)
     .then(data => {
        
       setModel(data.data.data);
       
       initialValues=data.data.data; 
       setData(data.data.data)
       setSurveyChildren(data.data.info_chiled);
       setdatainfo_user_name(data.data.user_name);
       
   formik.setValues(initialValues);
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
  
  }
  const handleFormTabs = (event: any) =>
  {
  
    if(event.data)
      setAddValue(event)
    
   
  } 
  useEffect(() => {

    var s=info_user_date;
    debugger
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
 
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
      setSurveyChildren(data.data.children)
  
       setLoading(false)
      load_data();

       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);

  return (
     
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>  info  -
          {/* { params.id } */}

          <span className='card-label fw-bolder fs-3 mb-1'> 
          { params.id }
       
          </span>
          </span>
       
        </h3>
        
        <div className='card-toolbar'>
       
       <Link to={"/crafted/surveys/dashboard/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
        رجوع </Link> 
        <ReactToPrint 
     trigger={()=> <button> طباعة</button>}
     content={()=> componentRef.current}
     copyStyles={true}

    //  pageStyle={pageStyle}
/>


       {/* <Link to="add">new </Link> */}
     </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
     
      <div className='card-body py-3'     ref={componentRef}
       dir="rtl">
        {/* begin::Table container */}
        <br/>

    
 <div className='row'   style={{border: '2px solid black'} }>
  
 
  <div  className='col-4 '>
           
<div className='row '>

<div  className='col-12 '>
<h3>
محمد ضيف الله واخوانة
</h3>
</div>
</div>
<div className='row '>
<div  className='col-12 '>
<h3>
للتجارة 
</h3>
</div>
</div>
<div className='row '>
<div  className='col-12 '>
  <h3>           جولات - اكسسوارات - برمجة - صيانة</h3>
</div>
</div>
<div className='row '>
                <div  className='col-12 '>
                      <h3>           777155449-01238220</h3>
                </div>
                </div>
              </div>
                   <div  className='col-4 text-center'>

    
                   <div className='row text-center'>
          
          <div  className='col-12 text-center'>
          <img
    alt='Logo'
    src={toAbsoluteUrl('/media/logos/MD.jpg')}
    className='h-100px mb-10'
  />

                </div>
                </div>


                <div className='row text-center'>
          
          <div  className='col-12 text-center'>
            <h3>
        {Surveys?.title}
          </h3>
                </div>
                </div>


                 
              </div>
            
  <div  className='col-4 text-center'>
           
           <div className='row text-center'>
          
          <div  className='col-12 text-center'>
            <h3>
          محمد ضيف الله واخوانة
          </h3>
                </div>
                </div>
                <div className='row text-center'>
                <div  className='col-12 text-center'>
            <h3>
          للتجارة 
          </h3>
                </div>
                </div>
                <div className='row text-center'>
                <div  className='col-12 text-center'>
                      <h3>           جولات - اكسسوارات - برمجة - صيانة</h3>
                </div>
                </div>
                <div className='row text-center'>
                <div  className='col-12 text-center'>
                      <h3>           777155449-01238220</h3>
                </div>
                </div>
                                  </div>
              </div>

<br/> 
        <div className=' ' style={{border: '2px solid black'} } >
     
 
        <br/> 

  
 
 <div className='row' >
  
  {dataList && dataList.map((plan:any, index:number) => {
                  return (
                    <>
                    <div  className='col-3 text-center'>
                    <h4>
                   {plan.title}
                   </h4>

                     </div>
                          <div  className='col-3   '>

                          <h4>
                          {Data[plan.status_type]}
                          </h4>

                        
                     </div>
                 
              </>
                    
                    );
                  })} 
            
  
  
    
  </div>
  {/* </div> */}
 
  <div className='table-responsive'>
  <br/> 
         

  {SurveyChildren && SurveyChildren.map((main_data:any, index_root:number) => {
                  return (
                 
                
      
  <table className='table align-middle gs-0 gy-4' 
  dir="rtl" key={index_root} >
          {/* begin::Table head */}
          <thead>
            <tr key={index_root} className='fw-bolder text-muted bg-light'
                style={{border: '1px solid black'}}
                >

            
            {main_data.sam_add && main_data.sam_add.map((plan:any, index:number) => {
                    return (
                      <th className='min-w-125px text-center' key={index}>{plan.title}</th>

                      );
                    })} 
            
              {/* <th className='min-w-200px text-end rounded-end'></th> */}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
       


                    {main_data.data_list &&main_data.data_list.map((item:any, index:number) => {
                    return (
                      <tr
                      key={index}
                style={{border: '1px solid black'}}

                      >
                            {main_data.sam_add && main_data.sam_add.map((zitem:any, index_2:number) => {
                    return (
                      
                      <td key={index_2}>
                  
                      
                    <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                    {item[zitem?.status_type]}
                    </span>
                
                      {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Paid</span> */}
                    </td>  
                      );
                    })} 
               
          
            
                       
                      </tr>
                    )
                  })} 

         


           
          
           
          </tbody>
          {/* end::Table body */}
        </table>
                    );
                  })} 
          {/* end::Table */}   
  
          </div>
  
          <div className='row text-center'>
          <div  className='col-3 text-center'>
                      <h3>           </h3>
                </div>
                <div  className='col-3 text-center'>
                      <h3>           </h3>
                </div>
                <div  className='col-3 text-center'>
                      <h3>          الاجمالي</h3>
                </div>
                <div  className='col-3 text-center'>
                      <h3>       {Data?.amount}  </h3>
                </div>
                </div>

  </div>
 
          {/* {paginationBasic} */}
          <div className='row'>
    <div  className='col-4 text-center'>
    تاريخ التقرير : {info_user_date}
            </div>
   <div  className='col-4 text-center'>
    
            </div>
            <div  className='col-4 text-center'>
            طبع بواسطة : {info_user_name
}
      </div>
    </div>

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
  

      </div>
      {/* begin::Body */}
    </div>

  )
}
