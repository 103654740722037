/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, FileUpload, get_data, get_data_json, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}


const AddSubSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseData:any;
  // Records per page to be shown & loaded
  id: number;
  root_id:number;

}

export const AddSub = ({
  apiRoute,
  responseData,
  id ,
  root_id,
}: Props) => {
// class AddSub extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
 

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: AddSubSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:'', 
          recipient_id:''
        }
        setShowAlert(false)
        
      
        api_add( 'sam_role_create/'+root_id, sat)
          .then((data:any)  => {
            

            if(data.data.status)
            {

              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
              setLoading(false)
              responseData(false);
            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)
              setLoading(false)


            }
    

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('AddSub process has broken')
          })
      }, 1000)
    },
  });
 
  useEffect(() => {
    if(root_id )
    {
      get_data_json('data_add_'+root_id )
 
    //  get_data('sam_role_add/'+root_id )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
  
       setLoading(false)
 
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
   
  useEffect(() => {

 
   }, []);
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{Surveys?.title_root} 
            {/* { params.id } */}
         
            </span>
          <span className='card-label fw-bolder fs-3 mb-1'>    </span> 

            <br/>
            {/* { params.id } */}
           
           </h3>
          <div className='card-toolbar'>
         
       
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>

          {showAlert && (
          
          <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
          <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
          <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
            <h5 className="mb-1">This is an alert</h5>
            <span>{status_massage}.</span>
          </div>
        
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
          </button>
        </div>
         
                )}

            {/* begin::Table */}
   
            <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
      
        {/* end::Heading */}
  
        {/* begin::Action */}
      
        {/* end::Action */}
 
  
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
  
    
      {/* begin::Form group Firstname */}
  
      <div className='row fv-row mb-7'>
  
{dataList && dataList.map((plan:any, index:number) => {
                return (
               
              
      <Panel2 data_ob={plan} data_index={index} formik={formik}
      value_object={initialValues} />
         
                  );
                })} 
          


  
</div>
 

        {/* end::Form group */}
   
      
        {/* end::Form group */}
  
        {/* begin::Form group */}
        <div className='text-left'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            disabled={!formik.isValid  }
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
              onClick={(e) => responseData(false)}

            >
              الغاء
            </button>
         
        </div>
        {/* end::Form group */}
      </form>
        
            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default AddSub;