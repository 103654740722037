/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Modal, Row, Toast } from 'react-bootstrap-v5'
import { Link, useHistory, useParams } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { delete_api, get_data } from '../api'  
import { Pagination7 } from '../pagination7'
// import { useForm } from "react-hook-form";
import * as Yup from 'yup'
import clsx from 'clsx'  

 
 
type Props = {
  className: string
}

const loginSchema = Yup.object().shape({
  searchText: Yup.string()

    .required('Email is required'),

})

const initialValues = {
  searchText: '',
}
const INITIAL_STATE = [{ 
  id: 0,
  username: '',
  password: '',
  email: '',
  firstname: '',
  lastname: ''}];


const SurveyList: React.FC<Props> = ({className}) => {

  // const { slice, range } = useTable(data, page, rowsPerPage);
 
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // setLoading(false)
      setSearch(values)

      setTimeout(() => {
         debugger
      
      }, 1000)
    },
  })
  

 
  const [loading, setLoading] = useState(false)

  const [type_id, setType] = useState(true); 
  const params = useParams<any>();
  const [Data, setData] = useState([] as any);
  const [isLoadingData, setLoadingData] = useState(false);
  
 
  const d=useHistory();

  const [showA, setShowA] = useState(true);  
 
  const toggleShowA = () => setShowA(!showA);
  const [lgShow, setLgShow] = useState(false);
 
 const [students, setStudents] = useState([] as any);
 const [isLoadingStudents, setLoadingStudents] = useState(false);
  const [filter, setFilter] = useState('');

 const [show, setShow] = useState(false);
 
 const [isSearchingData, setSearchingData] = useState(false);

 const [TransInfo, setDataTransInfo] = useState({} as any);
 
 const [Surveys, setSurveys] = useState({} as any);
 const [dataList, setList] = useState([] as any);
 const [dataListView, setListView] = useState([] as any);
 const [dataListInfo, setListInfo] = useState([] as any);
 
 const [type_accpet, setType_accpet] = useState('accpet');

 const [recordsPerPage, setRecordsPerPage] = useState(5); 
 const [isSearchingStudents, setSearchingStudents] = useState(false);
 const [reload, setReload] = useState(false);
 const [search, setSearch] = useState({} as any);
 const [data_ob, setDataOb] = useState({} as any);
 // const { register, handleSubmit, errors } = useForm();

 const load_data = () => {
    
  setData([])
  setSurveys({}) ;

  setReload(true);
  setTimeout(() => {
    setReload(false);
  }, 1000);
  get_data('sam_role_view/'+params.type )
  .then(data => {
   setSurveys(data.data.data) ;
   setList(data.data.data.children) ;
   setStudents(data.data.data.children)
    setLoading(false)
    // dispatch(auth.actions.login(accessToken))
  })
  .catch(() => {
    setLoading(false)
 
  })
};
  // const { slice, range } = useTable(data, page, rowsPerPage);
  if(params.type )
  {
    if(params.type!=type_id)
    {
    setType(params.type)

    load_data()
    console.log('useEffect 2');

    }
    
  }

 const handleLgClose = () =>
 {
  setLgShow(false);
  setReload(true);
  setTimeout(() => {
    setReload(false);
  }, 1000);
 } 
 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);

 // Fetch students
//  const fetchStudents = () => {
//    setLoadingStudents(true);
//    axiosInstance
//      .get(`/api/students`)
//      .then((response) => {
//        setLoadingStudents(false);
//        setStudents(response.data);
//      })
//      .catch((error) => {
//        setLoadingStudents(false);
//        console.log(error);
//      });
//  };
  
 
  /*
   * Clear search results
   * Make search object empty if no value
   * Trigger reload in pagination
   */
  const clearSearchResults = (event: any) => {
    if (!event.target.value && search) {
      setSearch({
        term: "",
      });
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 1000);
    }
  };
 
  const delete_data = (event: any,id:number) => {
    debugger
   
    handleClose()
    delete_api('currencies/'+id)
    .then(data => {
      
      debugger 
 
      setLoading(false)
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 1000);
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
      setLoading(false)
   
    })
  };
  
  const delete_data_info = (event: any,value:any) => {
    debugger
    setShow(true);
    setDataOb(value)
  };
  const edit_data_info = (event: any,value:any) => {
    
    setDataOb(value)
    setLgShow(true);

  };
  return (
    
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'></span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          <a href={'/crafted/surveys/dashboard/'+params.type} className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            <Link to={'/crafted/surveys/dashboard/'+params.type}>رجوع </Link>
          </a>
          
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}

   

          {/* <Row>
      <Col md={6} className="mb-2">
        <Button onClick={toggleShowA} className="mb-2">
          Toggle Toast <strong>with</strong> Animation
        </Button>
        <Toast show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
      </Col>
      </Row> */}
  <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div className="flex-grow-1 filter-container-c">
          <div className="search">
            {/* <Form onSubmit={handleSubmit(setSearch)}> */}
         
           
          </div>
        </div>
      </div>
      <Row>
        <Col md={12} lg={12}>
          <h3></h3>
          <Card>
            <Card.Body className="pt-0">
              {/* <Table striped hover>
                <thead>
                  <tr>
                    <th className="bt-none">Name</th>
                    <th className="bt-none">Email</th>
                    <th className="bt-none">Department</th>
                  </tr>
                </thead>
             
              </Table> */}
 
              <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
        

      <thead>
              <tr className='fw-bolder text-muted bg-light'>
 



           
 
         <th className='min-w-125px text-center'>الرقم</th> 
         <th className='min-w-125px text-center'>الاسم</th>
         {/* <th className='min-w-125px'>zform</th> */}
         <th className='min-w-125px text-center'>النوع</th> 
         <th className='min-w-125px text-center'>action</th>
     </tr>
            </thead>




  {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {!students.length && !isLoadingStudents && (
                    <tr className="text-center">
                      <td colSpan={3}>لاتوجد بيانات</td>
                    </tr>
                  )}
                  {isLoadingStudents && (
                    <tr className="text-center">
                      <td colSpan={3}>
                        <span>Loading...</span>
                      </td>
                    </tr>
                  )}
   
                      {!isLoadingStudents &&students.map((plan:any, index:number) => {
                      return (
                        <tr
                        key={index}
                        >

             <td className='text-center' >
              
                  <span className='text-muted fw-bold text-muted d-block fs-7'> {plan.id}</span>
                </td>         
                    {/* <td>
                  <span className='text-muted fw-bold text-muted d-block fs-7'> {plan.name}</span>
                </td>           */}
                
                   <td className='text-center' >
              
                  <span className='text-muted fw-bold text-muted d-block fs-7'> {plan.title}</span>
                </td>        
                
                     {/* <td>
              
                  <span className='text-muted fw-bold text-muted d-block fs-7'> {plan.zform}</span>
                </td>        */}
                                
                      <td className='text-center' >
              
                  <span className='text-muted fw-bold text-muted d-block fs-7'> {plan.ztype}</span>
                </td>       <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                                 
                                
 
                <Link to={'/crafted/surveys/dashboard/'+params.type+`/list/${params.subid}/show/${plan.root_id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
         
                                  </Link>
                           
 
                  
                            </td>

                        </tr>
                      )
                    })} 

           
 

             
            
             
            </tbody>


            {/* end::Table body */}
          </table>

            
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>

      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>هل انت متاكد من حذف
          {data_ob.id}
          {data_ob.name}
        </p>
        Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" 
              onClick={(e) => delete_data(e,data_ob.id)}
              >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.id}
          {data_ob.name}

      

        </Modal.Body>
      </Modal>
      
 

    </div>
    
  )
}

export {SurveyList}
 


