/* eslint-disable jsx-a11y/anchor-is-valid */
 
import React, {useEffect, useState} from 'react'

import { Dropdown2 } 
from '../../../../_metronic/partials/content/dropdown/Dropdown2'

import {KTSVG} from '../../../../_metronic/helpers'
import {Dropdown1} 
from '../../../../_metronic/partials/content/dropdown/Dropdown1'
 
import { Dropdown3 } 
from '../../../../_metronic/partials/content/dropdown/Dropdown3'

 
import { api_add, api_put, get_data, user_add } from '../api'

type Props = {
  className: string
}

const ListsWidget5Sam: React.FC<Props> = ({className}) => {

    const [menu, setMenu] = useState([] as any);
    const [model, setModel] = useState({} as any)
    const [dataListView, setListView] = useState([] as any);
    useEffect(() => {
      get_data('invoice_items_today?skip=0&top=20')
      .then(data => {
        
        debugger
        
        setListView(data.data.data);
 

        
  
        // dispatch(auth.actions.login(accessToken))
      })
      .catch(() => {
      
     
      })
     
   
  
     
    }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>مبيعات اليوم</span>
          <span className='text-muted fw-bold fs-7'>890,344 Sales</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          {/* <Dropdown1 /> */}
          <Dropdown2 />
          {/* <Dropdown3 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
    
          {/* end::Item */}
          {/* begin::Item */}

          {dataListView && dataListView.map((plan:any, index:number) => {
                      return (
                  
                  <>
                  
                  <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bolder text-gray-800 fs-6'>{plan.total}</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-success fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Content */}
            <div className='timeline-content d-flex'>
              <span className='fw-bolder text-gray-800 ps-3'>{plan.item_name}</span>
            </div>
            {/* end::Content */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
                  </>
                        );
                      })}

         
         
         
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ListsWidget5Sam}
