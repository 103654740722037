/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, FileUpload, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { FormItemSelect } from './FormItemSelect'


let initialValues = {
  p_key: 0,  
  filed2: '',
  price: 0, 
  quantity: 0,
  total:0, 
  is_delete:false,
  sam_chiled:[]


}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseDataObject:any;
  // Records per page to be shown & loaded
  id: number;
  dataList:any;
  ticket:any;
}

export const FormObjectItems = ({
  apiRoute,
  responseDataObject,
  id ,
  dataList,
  ticket,
}: Props) => {
// class FormObjectItems extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any); 
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
  const onChangetotal = (event: any) =>
    {
    
      debugger
   let s =   formik.values.quantity;
    
 if(s&&event)
 {
   formik.setFieldValue('total',event*s);
  // formik.setFieldValue('total7',event*s);
setTimeout(() => {
 save_data(event*s)
  
}, 100);
 }else if(event)
 {
  // formik.setFieldValue('total',event*0);

 }

    } 

    const onChangetotal2 = (event: any) =>
    {
    
      debugger
   let s =   formik.values.price;
    
 if(s&&event)
 {
  formik.setFieldValue('total',event*s); 
  setTimeout(() => {
    save_data(event*s)
     
   }, 100);
 }else if(event)
 {
  // formik.setFieldValue('total',event*0);

 }
//  save_data(event)

    } 

    const handleLgClose = (event: any) =>
    {
    
      debugger

    } 
  // initialValues=ticket;
  //  formik.setValues(initialValues);

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:values,
          AddedDate:AddedDate,
          father_key:'',
          data_object:'', 
          id:id, 
          recipient_id:''
        } 
         
        // responseDataObject(sat);
       
      }, 1000)
    },
  });
  
  const save_data = (event: any) => {
    debugger
  let values=formik.values;
    values.sam_chiled=[];
    values.total= event;   
     
    let sat={
      data:values,
      AddedDate:AddedDate,
      father_key:'',
      data_object:'', 
      id:id, 
      total:event, 
      is_delete:values.is_delete,  
      recipient_id:''
    } 
     
    responseDataObject(sat);
  
  }

  const save_data_delete = (event: any) => {
    debugger
  let values=formik.values;
    values.sam_chiled=[];
    values.total= event;   
     
    let sat={
      data:values,
      AddedDate:AddedDate,
      father_key:'',
      data_object:'', 
      id:id, 
      total:event, 
      delete:true, 
      
      recipient_id:''
    } 
     
    responseDataObject(sat);
  
  }
  useEffect(() => {
    if(ticket.p_key)
    {
      // initialValues=ticket;
      formik.setValues(ticket);
    }
 
   }, []);
    return (
      <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
    
    >
      {/* begin::Heading */}
    
      {/* end::Heading */}

      {/* begin::Action */}
    
      {/* end::Action */}


      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

  
    {/* begin::Form group Firstname */}

    <div className='row fv-row mb-7'>

{dataList && dataList.map((plan:any, index:number) => {
              return (
              
                <>

{plan.ztype=='select' && (
      //  <Panel2 data_ob={plan} data_index={index} formik={formik} value_object={initialValues}/>

       
       <FormItemSelect key={index} apiRoute={`sam`}
       response7Data={handleLgClose}
       id={1}
       data_ob={plan}
       data_survey={plan}
       data_index={8} 
       formik={formik}
       ticket={ticket}
       /> 
      )}


                </>
       
                );
              })} 
        
 

       
    

      <div   className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> الكمية</label>
      <input 
        placeholder='quantity'
        type='number' 
        {...formik.getFieldProps('quantity')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur
          onChangetotal2(e.target.value)
      }}
      />
 
    </div>
    <div    className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> السعر</label>
      <input 
            
        placeholder={'price'}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps('price')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur
          onChangetotal(e.target.value)

          // and do something about e
          let someValue = e.currentTarget.value
           
      }}
      />
    </div>

    <div   className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> الاجمالي</label>
      <input 
        placeholder={'total'}
        type='number' 
        autoComplete='off'
        disabled={true}

        {...formik.getFieldProps('total')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    <div   className='col-xl-2'  >

    <label className='class="form-label fw-bolder text-dark fs-6'> الغاء</label>
      <input  
        type='checkbox'  
        className='form-check-input'
        {...formik.getFieldProps('is_delete')}
     
       
      />
 
</div>
</div>


      {/* end::Form group */}
 
    
      {/* end::Form group */}
      
      {/* begin::Form group */}
      
      {/* end::Form group */}
    </form>
  
    )
    
  
}

// export default FormObjectItems;