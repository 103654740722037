/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { URLSearchParams } from 'url'
import { useParams} from 'react-router-dom' 
import { RouteComponentProps, useHistory} from 'react-router-dom' 


const loginSchema = Yup.object().shape({
  email: Yup.string() 
    .min(9, 'Minimum 8 symbols')
    .max(9, 'Maximum 9 symbols')
    
    .required('phone is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '77',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
const params = useParams<any>();
// const paramsString = "q=URLUtils.searchParams&username=api";
// const searchParams = new URLSearchParams(paramsString);

// const qPar=new URLSearchParams(window.location.search); 
  const dispatch = useDispatch()
  useEffect(() => {
 
    
  let url = new window.URL(window.location.toString());
  console.info(url.search)
    // const username1=qPar.get('username');
    let onr=url.search;
    let org2=onr.replaceAll('?','')
    let org3=org2.replaceAll('=','&')
    let d=org3.split('&')
    let username=d[1];
    const pass=d[5];
    let s={ email: username,
    password: pass}
//     const Search8 = searchParams.get("username")?
// searchParams.get("username"):"100";

    debugger
   formik.setValues(s);

    
    formik.setFieldValue("password",pass);
    formik.setFieldValue("email",username);

   }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(data => {
            setLoading(false)

            if(data.data.status)
            {
              dispatch(auth.actions.login(data.data.accessToken))
            }else
            {
            setStatus('The login detail is incorrect')

            }
      
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>تسجيل الدخول</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) }

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>رقم الجوال</label>
        <input
          placeholder='رقم الجوال'
          type='number'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
         
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>كلمة المرور</label>
            {/* end::Label */}
         
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={  !formik.isValid}
        >
          {!loading && <span className='indicator-label'>تسجيل الدخول</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              االرجاء الانتظار...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
 

        {/* begin::Google link */}
        
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
