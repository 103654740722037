/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
type Props = {
    className: any,
    info_list_sub:any,
  }
 
const QuickActionsT: React.FC<Props> = ({className,info_list_sub}) => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>العمليات</div>
      </div>

      <div className='separator mb-3 opacity-75'></div>
      
      {className.children && className.children.map((plan:any, index:number) => {
                      return (
                        <div key={index} className='menu-item px-3'>
                        <a   className='menu-link px-3'
                          onClick={(e) => info_list_sub(e,plan)}
                          >
                          {plan.root_title} 
                          
                        </a>
                      </div>
                        );
                      })} 
     

    
 
 

      <div className='separator mt-3 opacity-75'></div>

    
    </div>
  )
}

export {QuickActionsT}
