export interface Currencies_model {





 
  id: number
  name: string
  code: string 
  rate: number
}




export let initialValues = {
  sam_chiled:[],
  order_by:'',
  quantity:0,
  order_how:'',





  name: '', 
  code: '', 
  rate: 0, 
  id: 0, 
  symbol: '', 
}

