/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams,useHistory} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add,get_data_json } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { FormTabs } from './FormTabs'
 
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  total:0,
  filed5: '',
  sam_chiled:[]


}

const EditReturnSchema = Yup.object().shape({
 
 
})

export function EditReturn() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const d=useHistory();
  
  const params = useParams<any>();
 
 

    
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
 
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
  const [SurveyChildren, setSurveyChildren] = useState([] as any);
  const [AddValueTabs, setAddValue] = useState({} as any);
  

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: EditReturnSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:AddValueTabs, 
          recipient_id:''
        }
        setShowAlert(false)
        api_add( ''+Surveys.titleen+'_return/'+params.type+'/'+params.id, sat)
    
        // api_add( 'sam_role_update/'+params.type+'/'+params.id, sat)
          .then((data:any)  => {
            

            if(data.data.status)
            {

              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
              setLoading(false) 
        d.goBack()

            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)
              setLoading(false)


            }
    

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Edit process has broken')
          })
      }, 1000)
    },
  });
  const load_data = () => {
  
    if(params.id)
    {
 
     get_data('sam_role_edit/'+ params.type +'?id='+params.id)
     .then(data => {
       
       
       setModel(data.data.data);
       initialValues=data.data.data;
   formik.setValues(initialValues);
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
  
  }
  const handleFormTabs = (event: any) =>
  {
  
 
    
      if(event.data)
      {
        setAddValue(event)
        if(event.total)
        {
        formik.setFieldValue('total',event.total);
     
    
        }
    
      }
      
  } 
  useEffect(() => {
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
 
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
      setSurveyChildren(data.data.children)
  
       setLoading(false)
      load_data();

       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);

  return (
 
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>  تعديل  -
          {/* { params.id } */}
          <span className='card-label fw-bolder fs-3 mb-1'> 
          { params.id } 
          </span>
          </span>
          <br/> 
        </h3>
        <div className='card-toolbar'> 
       <Link to={"/crafted/surveys/dashboard/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
        رجوع </Link>
       {/* <Link to="add">new </Link> */}
     </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}

        <div className='table-responsive'>
        {showAlert && (
          
          <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
          <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
          <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
            <h5 className="mb-1">رسالة خطأ</h5>
            <span>{status_massage}.</span>
          </div>
        
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
          </button>
        </div>
         
                )}
          {/* begin::Table */} 
          <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
 
 

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
 
 <div className='row fv-row mb-7'>
  
  {dataList && dataList.map((plan:any, index:number) => {
                  return (
                 
                
        <Panel2 data_ob={plan} data_index={index} formik={formik} value_object={initialValues}/>
           
                    );
                  })} 
            
  
  
    
  </div>
   
  <FormTabs   apiRoute={`sam`} id={1}  dataList={SurveyChildren}
                responseDataTabs={handleFormTabs}/>


     
      {/* begin::Form group */}
      <div className='text-right'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-20 mb-5'
          disabled={!formik.isValid  }
        >
          {!loading && <span className='indicator-label'>حفظ</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        
      </div>
      {/* end::Form group */}
    </form>
      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}
    </div>

  )
}
