/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState,useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams,useHistory} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add,get_data_json } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { FormTabs } from './FormTabs'
import ReactToPrint from 'react-to-print'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
 
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}

const ListAccountsTransSchema = Yup.object().shape({
 
 
})

const pageStyle=`
  
@media all {
  .page-break {
    display: none;
  }
}
@media print {
  .html,body {
   height: inherit !important;
   overflow: inherit !important;
   -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
@page {
  size: auto;
  margin: 20mm;
} 
table, th , td
{
  border:1px solid black;
  border-collapse:collapse;
}
`;
export function ListAccountsTrans() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const d=useHistory();
  
  const params = useParams<any>();
 
 

    
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
 
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
  const [SurveyChildren, setSurveyChildren] = useState([] as any);
  const [DataChildren, setDataChildren] = useState([] as any);
  const [Data, setData] = useState([] as any);
  const [dataListView, setdataListView] = useState([] as any);
  const [dataListTrans, setdataListTrans] = useState([] as any);
  const [dataDebit, setdataDebit] = useState(0);
  const [dataCredit, setdataCredit] = useState(0);
  const [datatotal, setdatatotal] = useState(0);
  const [info_msg, setdatainfo_msg] = useState(0);
  const [info_user_name, setdatainfo_user_name] = useState(null);
  const [info_user_date, setdatainfo_user_date] = useState(null);
  
  


  const componentRef=useRef(null as any);
  



  const [AddValueTabs, setAddValue] = useState({} as any);
  

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: ListAccountsTransSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:AddValueTabs, 
          recipient_id:''
        }
        setShowAlert(false)
    
      
      }, 1000)
    },
  });

  
  const load_data_trans = () => {

    get_data('accounts_list_trans/'+params.id)
    .then(data => {
          
      setdataListTrans(data.data.list);
      setdataDebit(data.data.Debit);
      setdataCredit(data.data.Credit);
      setdatatotal(data.data.total);
      setdatainfo_msg(data.data.info_msg)
      setdatainfo_user_name(data.data.user_name)
      setdatainfo_user_date(data.data.user_date)
      
      
      setLoading(false)
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
      setLoading(false)
   
    })

  }


  const load_data = () => {
    
    if(params.id)
    {
 
     get_data('sam_role_info/'+ params.type +'?id='+params.id)
     .then(data => {
        
       setModel(data.data.data);
       
       initialValues=data.data.data; 
       setData(data.data.data)
       setSurveyChildren(data.data.info_chiled);
       
       load_data_trans()
   formik.setValues(initialValues);
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
  
  }
  const handleFormTabs = (event: any) =>
  {
  
    if(event.data)
      setAddValue(event)
    
   
  } 
  useEffect(() => {
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
 
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
      setSurveyChildren(data.data.children)
  
       setLoading(false)
      load_data();

       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }

 
   }, []);

  return (
     
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'> 
           {/* info  - */}
          {/* { params.id } */}

          <span className='card-label fw-bolder fs-3 mb-1'> 
          {/* { params.id } */}
       
          </span>
          </span>
       
        </h3>
        
        <div className='card-toolbar'>
       
       <Link to={"/crafted/surveys/dashboard/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
        رجوع </Link>

        <ReactToPrint 
     trigger={()=> <button> print this out</button>}
     content={()=> componentRef.current}
     copyStyles={true}

     pageStyle={pageStyle}
/>


       {/* <Link to="add">new </Link> */}
     </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
     
      <div className='card-body py-3'     ref={componentRef}
      dir="rtl">
        {/* begin::Table container */}
        <br/>

        
 <div className='row'   style={{border: '2px solid black'} }>
  
 
            <div  className='col-4 text-center'>
                     
 <div className='row text-center'>

<div  className='col-12 text-center text-danger '>
  <h3 className=' text-danger '>
محمد ضيف الله واخوانه
</h3>
      </div>
      </div>
      <div className='row text-center'>
      <div  className='col-12 text-center'>
  <h3 className=' text-danger '>
للتجارة 
</h3>
      </div>
      </div>
      <div className='row text-center'>
      <div  className='col-12 text-center'>
            <h3>           جولات - اكسسوارات - برمجة - صيانة</h3>
      </div>
      </div>
                        </div>
                             <div  className='col-4 text-center'>

              
                             <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/MD.jpg')}
              className='h-100px mb-10'
            />

                           
                        </div>
                      
            <div  className='col-4 text-center'>
                     
                     <div className='row text-center'>
                    
                    <div  className='col-12 text-center'>
                      <h3 >
                    محمد ضيف الله واخوانه
                    </h3>
                          </div>
                          </div>
                          <div className='row text-center'>
                          <div  className='col-12 text-center'>
                      <h3>
                    للتجارة 
                    </h3>
                          </div>
                          </div>
                          <div className='row text-center'>
                          <div  className='col-12 text-center'>
                                <h3>           جولات - اكسسوارات - برمجة - صيانة</h3>
                          </div>
                          </div>
                                            </div>
                        </div>

<br/>
<br/>
<br/> 
        <div className=' '>
     
 

  
 
 <div className='row'>
  
  {dataList && dataList.map((plan:any, index:number) => {
                  return (
                 <>
                       <div  className='col-3 text-center'>
                       <h4>
                      {plan.title}
                      </h4>

                        </div>
                             <div  className='col-3   '>
 
                             <h4>
                             {Data[plan.status_type]}
                             </h4>

                           
                        </div>
                    
                 </>
                    
                    );
                  })} 
            
  
  
    
  </div>
  </div>
 
  <div className='table-responsive'>
  <br/> 
          
       {/* begin::Body */}
       <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {/* style="border: 1px solid black;color: black;" */}
          <table className='table align-middle gs-1 gy-1 ' 
          style={{border: '1px solid black'} }
          dir="rtl"
           >
            {/* begin::Table head */}
            <thead >
              <tr className='fw-bolder text-danger text-muted bg-light'
                style={{border: '1px solid black',textDecorationColor:'#fffff'}}
                > 
                <th className='text-center text-danger  bg-light'   style={{width:"9rem"}} >  التاريخ</th>
                <th className='text-center text-danger  bg-light'  style={{width:"9rem"}}>نوع المستند</th>
                <th className='text-center text-danger  bg-light' style={{width:"6rem"}}>رقم المستند</th>
                <th className='text-center text-danger  bg-light' style={{width:"12rem"}}>البيان </th>
                <th className='text-center text-danger  bg-light'  style={{width:"7rem"}}>رقم المرجع</th>
                <th className='text-center text-danger  bg-light'  style={{width:"9rem"}}>مدين</th>
                <th className='text-center text-danger  bg-light' style={{width:"9rem"}}>دائن</th>
                <th className='text-center  text-danger  bg-light' style={{width:"9rem"}}>الرصيد</th> 
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody   >

                
                {dataListTrans && dataListTrans.map((plan:any, index:number) => {
                  return (
                 <>
              <tr style={{border: '1px solid black'} } >
 
                <td>
                  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"7rem"}}>
                   {plan.paid_at}
                  </a>
                 </td>
                <td>
                  <a className='text-primary text-center fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"7rem"}}>
                   {plan.type}
                  </a>
                 </td>
                <td>
                  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"4rem"}}>
                   {plan.reference}
                  </a>
                </td>
                <td>
                  <a className='text-primary text-rigth fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"12rem"}}>
                   {plan.description}
                  </a>
                </td>
                <td>
                  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"5rem"}}>
                   {plan.document_id }
                  </a>
                 </td>
                <td>
                  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"5rem"}}>
                   {plan.Debit}
                  </a>
                 </td>
                <td>
                  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"5rem"}}>
                  {plan.Credit}
                  </a>
             </td>
                
                <td>
                  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6' style={{width:"5rem"}}>
                   
                  </a>
                 </td>
               
              
              </tr>
                    
                 </>
                    
                    );
                  })} 

              
<tr>

                  
                
<td>
  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
  العدد
  </a>
 
</td>
<td>
  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
  {dataListTrans.length}
  </a>

</td>
<td>
  
  </td>
<td>
  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
  اجمالي العمليات
  </a>
 </td>
<td>
  
  </td>
<td>
  <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
 
  {dataDebit}
  </a>
 </td>
<td>
  <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
 
 {dataCredit}
  </a>
  </td>

<td>
 
  </td>
 

</tr>



<tr>

                  
                
<td>
  <a className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
  اجمالي الرصيد الذي
  {info_msg}
  </a>
 
</td>
<td>
 
</td>
<td>
  
  </td>
<td>
 
 </td>
<td>
  
  </td>
<td>
  <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
   
  </a>
 </td>
<td>
  <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
  {datatotal}
  
  </a>
  </td>

<td>
 
  </td>
 

</tr>

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}



      
  </div>
 
          {/* {paginationBasic} */}
 
    <div className='row'>
    <div  className='col-4 text-center'>
    تاريخ التقرير : {info_user_date}
            </div>
   <div  className='col-4 text-center'>
    
            </div>
            <div  className='col-4 text-center'>
            طبع بواسطة : {info_user_name
}
      </div>
    </div>
        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
  

      </div>
      {/* begin::Body */}
    </div>

  )
}
