/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component,useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { Col, Row } from 'react-bootstrap-v5'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from './FileUploadServices' 
import { FormSelect } from './FormSelect'

let initialValues = {
  title: '',
  name: '',
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[],
  order_by:'',
  quantity:0,
  order_how:'',

}

const FilterSchema = Yup.object().shape({
 
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  dataObject: any;
  responseData:any;
  dataList:any;
  dataList2?:any;

}
export interface IPanelProps {
  txt?: string;
  data_ob: any;
  formik: any;
  data_index: number;
  
  value_object: any;
}

export class Panel2Filter extends Component<IPanelProps, {}> {
  value_select: any[]=[];
  
  render() {
    
 

    const { txt } = this.props;
    const { data_ob } = this.props;
    const { data_index } = this.props;
    const { formik } = this.props;
    const { value_object } = this.props;
    
    // this.value_select=[];

    const load_data = () => {

     
      
      
      
    }
    const handleLgClose = (event: any) =>
    {
    
      
      formik.setFieldValue(event.key,event.value);
    } 
    const onChangetotal = (event: any) =>
    {
    
      
   let s=   formik.values.quantity;
   

 if(s&&event)
 {
  formik.setFieldValue('total',event*s);
  formik.setFieldValue('total7',event*s);

 }else if(event)
 {
  formik.setFieldValue('total',event*2);

 }
    } 
    // react-hooks/rules-of-hooks
    
  
    if (data_ob.ztype=='textfield')
    {
      return    <div  key={data_index} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='text' 
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)} 
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched[data_ob.status_type] && data_ob.zform && !formik.getFieldProps(data_ob.status_type).value,
          },
          { 
             'is-valid': formik.touched[data_ob.status_type] &&  formik.getFieldProps(data_ob.status_type).value,
          }
        )}
      />
      
             {formik.touched[data_ob.status_type] && data_ob.zform &&  !formik.getFieldProps(data_ob.status_type).value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>
    }else if (data_ob.ztype=='phone')
    {
      return    <div  key={data_index} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='tel'
        
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
      
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched[data_ob.status_type] && data_ob.zform && !formik.getFieldProps(data_ob.status_type).value,
            
          },
          {
         
             'is-valid': formik.touched[data_ob.status_type] &&  formik.getFieldProps(data_ob.status_type).value,
          }
        )}
      />
      
             {formik.touched[data_ob.status_type] && data_ob.zform &&  !formik.getFieldProps(data_ob.status_type).value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>
    }else if (data_ob.ztype=='email')
    {
      return    <div  key={data_index} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='email'
        
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
      
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched[data_ob.status_type] && data_ob.zform && !formik.getFieldProps(data_ob.status_type).value,
            
          },
          {
         
             'is-valid': formik.touched[data_ob.status_type] &&  formik.getFieldProps(data_ob.status_type).value,
          }
        )}
      />
      
             {formik.touched[data_ob.status_type] && data_ob.zform &&  !formik.getFieldProps(data_ob.status_type).value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>
    }else if (data_ob.ztype=='text'||data_ob.ztype=='textarea')
    {
      return    <div  key={data_index} className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <textarea
        placeholder={data_ob.title}
        value={formik.getFieldProps(data_ob.status_type).value}
     
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      >
       {formik.getFieldProps(data_ob.status_type).value}
      </textarea>
     
    </div>
    }else  if (data_ob.ztype=='password')
    {
      return  <div  key={data_ob} className='col-xl-4'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='password'
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='number')
    {
      return  <div  key={data_ob} className='col-xl-4'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='price')
    {
      return  <div  key={data_ob} className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
            
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps('price')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur
          onChangetotal(e.target.value)
          // and do something about e
          let someValue = e.currentTarget.value
           
      }}
      />
 
    </div>
    }else  if (data_ob.ztype=='quantity')
    {
      return  <div  key={data_ob} className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps('quantity')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='total')
    {
      return  <div  key={data_ob} className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        disabled={true}

        {...formik.getFieldProps('total')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='priority_key')
    {
      return  <div  key={data_ob} className='col-xl-4'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }
     
    else  if (data_ob.ztype=='file')
    {
      
    
      return  <FileUploadServices key={data_index} apiRoute={`sam`}
      responseData={handleLgClose}
      id={1}
      data_ob={data_ob}
      data_survey={data_ob}
      data_index={8} />

    }else  if (data_ob.ztype=='date')
    {
      return <>
      <div  key={data_ob} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title+' من'}</label>
      <input
        placeholder={data_ob.title+' من'}
        type='date' 
        {...formik.getFieldProps(data_ob.status_type+'_from')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
 
    </div>
    <div  key={data_ob} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title+' الى'}</label>
      <input
        placeholder={data_ob.title+' الى'}
        type='date' 
        {...formik.getFieldProps(data_ob.status_type+'_to')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
 
    </div>

      </>

    }else  if (data_ob.ztype=='userupdate'&&data_ob.id==886)
    {
      return   <div className={`card mb-1 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>  
         بيانات تخص الهيئة
          
          </span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
        </h3>
        <div className='card-toolbar'>
          
     

     
        </div>
      </div>   
 
    </div>
    }else  if (data_ob.ztype=='time')
    {
      return   <div  key={data_ob} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='time' 
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
 
    </div>
    }else  if (data_ob.ztype=='bit1')
    {
   

      return   <div  key={data_ob} className='col-xl-2'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        type='checkbox' 
        data-kt-check='true'
        value={1}
        data-kt-check-target='.widget-13-check'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-check-input', 
        )}
      />
 
    </div>
    }else if (data_ob.ztype=='select')
    {


      return  <FormSelect key={data_index} apiRoute={`sam`}
      response7Data={handleLgClose}
      id={1}
      data_ob={data_ob}
      data_survey={data_ob}
      data_index={8} 
      formik={formik}/>

       

    }else
    {
      return <div></div>
    }
    
  }
}

export const Filter = ({
  apiRoute,
  responseData,
  dataObject,
  dataList,
  dataList2,

}: Props) => {
// class Filter extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)

 
  const dispatch = useDispatch()
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: FilterSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
         
        responseData(JSON.stringify(values));
      }, 1000)
    },
  });

    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>البحث 
            {/* { params.id } */}
         
            </span>
            <br/>
            {/* { params.id } */}
 
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
         
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
   
            <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
 
        {/* end::Heading */}
  
        {/* begin::Action */}
      
        {/* end::Action */}
  
     
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
  
        {/* begin::Form group Firstname */}
      
        {/* <div className='row fv-row mb-3'> */}
        <div className='row fv-row mb-7'>


        {/* <Row> */}
              
{dataList && dataList.map((plan:any, index:number) => {
                return (
        // <div className=' mb-3'>
                // <Col md={6} >
                <Panel2Filter data_ob={plan} data_index={index} formik={formik} value_object={initialValues}/>


                // </Col>
              
 
                  );
                })} 
                   {/* </Row> */}
          
 
    <div   className='col-xl-4'  >
    
    <label className='class="form-label fw-bolder text-dark fs-6'> الترتيب بحسب</label>
 
 <select
        placeholder='order_by'  
        {...formik.getFieldProps('order_by')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      >
        
          
        <option value="0">اختيار القيمة</option>
        
          
          {dataList2 && dataList2.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.titleen} >{plan.title}</option>

                        );
                      })} 

 
        </select>
      
    </div>

    <div   className='col-xl-4'  >
    
    <label className='class="form-label fw-bolder text-dark fs-6'> الترتيب تصاعدي</label>
 
 <select
        placeholder='order_how'  
        {...formik.getFieldProps('order_how')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      >
        
           
        <option value="ASC">تنازلي </option>
        <option value="DESC"> تصاعدي </option> 
 
        </select>
      
    </div>
    

    </div>
  
{/* </div> */}
        {/* end::Form group */}
   
      
        {/* end::Form group */}
  
        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid  }
          >
            {!loading && <span className='indicator-label'>بحث</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
       
        </div>
        {/* end::Form group */}
      </form>
        
            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default Filter;