/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl' 
import {TreeMenuItemWithSub} from './TreeMenuItemWithSub'
import {TreeMenuItem} from './TreeMenuItem'
import { get_data } from '../api'

export function TreeMenuMain() {
  const [model, setModel] = useState({} as any)

  const intl = useIntl()
  const [menu, setMenu] = useState([] as any);

  
  useEffect(() => {
    get_data('tree_account?skip=0&top=20')
    .then(data => {
       
      
      setModel(data.data);
      clearSearchResults(data.data)
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
   
 

   
  }, [])

  const clearSearchResults = (new_data:any) => {
   console.log('sam');
    const token=localStorage.getItem('navItems12'); 
    // 
    let s; 
    let s7: { name: any; url: string; icon: string; children?: any[] }[]=[]; 
    let s77: { name: any; url: string; icon: string; children?: any[] }[]=[]; 
    
    // let s7=[]; 
    // s7.push({name:'الرئيسية',url:'/dashsam/',icon:'icon-home'})

let data =new_data.role;
    // s7=s7.concat(s77);
  
    setMenu(data)

    
  };
  return (
    <>
      <TreeMenuItem
        to=''
        icon='/media/icons/duotune/art/art002.svg'
        title='الرئيسية'
        fontIcon='bi-app-indicator'
      />
      {/* <TreeMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>العمليات</span>
        </div>
      </div>
      
      {/* <TreeMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <TreeMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <TreeMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <TreeMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <TreeMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <TreeMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <TreeMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </TreeMenuItemWithSub>

        <TreeMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <TreeMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <TreeMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </TreeMenuItemWithSub>
      </TreeMenuItemWithSub> */}

      
      {menu&&menu.map((plan:any, index:number) => {
                      return (
                        <TreeMenuItemWithSub key={index}
                        to={'/crafted/'+plan.id}
                        title={plan.title}
                        icon='/media/icons/duotune/general/gen025.svg'
                        fontIcon='bi-layers'
                      >
                        
                        {plan.children&&plan.children.map((plan_2:any, index_2:number) => {
                      return (
                     <>
                         {plan_2.children&&plan_2.children.map((plan_3:any, index_3:number) => {
                          return (

                            <>
                            {plan_3.children && (
                                 <TreeMenuItemWithSub key={index_3}
                                 to={'/crafted/'+plan_3.id}
                                 title={plan_3.title}
                                 icon='/media/icons/duotune/general/gen025.svg'
                                 fontIcon='bi-layers'
                               >
                                 
                                 {plan_3.children&&plan_3.children.map((plan_4:any, index_4:number) => {
                               return (
                              
                                  <TreeMenuItem  key={index_4} to={'/crafted/'+plan_4.id} title={plan_4.title} hasBullet={true} />
         
                                 );
                                      
                               } ) } 
                               
                               </TreeMenuItemWithSub>

                            )}
                               {!plan_3.children && (
                                      <TreeMenuItem  key={index_3} to={'/crafted/'+plan_3.id} title={plan_3.title}  hasBullet={true}   />

                            )}

                            
                            </>
                         
                            );
                          })} 


       
{!plan.children && (
        
        <TreeMenuItem  key={index_2} to={'/crafted/'+plan_2.id} title={plan_2.title} hasBullet={true} />

      )}


                     </>
                    

                        );
                             
                      } ) } 
                      
                      </TreeMenuItemWithSub>
                        );
                      })} 

      {/* <TreeMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <TreeMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <TreeMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </TreeMenuItemWithSub>
      <TreeMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <TreeMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <TreeMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </TreeMenuItemWithSub> */}
      
      <TreeMenuItem to='/crafted/surveys/tree/3686' title='tree' hasBullet={true} />

      {/* <TreeMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <TreeMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <TreeMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <TreeMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <TreeMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <TreeMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <TreeMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </TreeMenuItemWithSub> */}


     
 

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
    
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
