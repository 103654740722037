/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import { useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 

import { api_add, api_put, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap-v5'
import { SubListAll } from './services/SubListAll'
import DatePicker, { DayValue, DayRange, Day } from 'react-modern-calendar-datepicker'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  response7Data:any;
  // Records per page to be shown & loaded
  id: number;
  data_ob: any; 
  data_survey: any; 
  data_index: number;
  formik: any;
}

export const FormDateStart = ({
  apiRoute,
  response7Data,
  id ,
  data_ob, 
  data_survey,
  data_index,
  formik,
}: Props) => {
// class FormDateStart extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [isListShow, setListShow] = useState(false);
  
  const [dataList, setList] = useState([] as any);
  const [value_select, SetValueSelect] = useState([] as any);
  const [value_select_old, SetValueSelectOld] = useState([] as any);
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf(); 
  const [recordsPerPage, setRecordsPerPage] = useState(''); 
  const [data_ob7, setDataOb] = useState({} as any);
  
  const [father_key_NO, setfather_key_NO] = useState(0); 
  const [new_value, setnew_value] = useState(0); 
  
  const [searchText, setsearchText] = useState(''); 

  const [lg7Show, setLg7Show] = useState(false);
  const [day, setDay] = React.useState<DayValue>(null);
  const [dayRange, setDayRange] = React.useState<DayRange>({
    from: null,
    to: null
  });
  const [days, setDays] = React.useState<Day[]>([]);

   
  const handleLgClose2 = (event: any) =>
  {
    debugger
    setDayRange(event)
    let res={value:event,
      key:data_ob.status_type
      }
    if(event.form)
    {
      let d=event.form.year+'-'+event.form.month+'-'+event.form.day;
       res={value:d,
        key:data_ob.status_type
        }
    }
  
   if(event.from&&event.to)
       response7Data(res)
  } 
  
  
 
  useEffect(() => {
   
 
   }, []);
   
 
    return (
 
        <div className='col-xl-4'>
 <div  className='row fv-row mb-7'>
 <div className='col-xl-9'>
      
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
     
      {/* <input
        placeholder={data_ob.title}
        type='datetime' 
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      /> */}
    {/* <DatePicker  
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy h:mm aa"
      showTimeInput
    /> */}

    <DatePicker value={day} 
           
  calendarPopperPosition='bottom'
          onChange={setDay}  
       />
      {/* <DatePicker value={dayRange}
      
  
      onChange={handleLgClose2}  /> */}
      {/* <DatePicker value={days} onChange={setDays} /> */}

    </div>
    
 </div>

        </div>
  
    
    )
    
  
}

// export default FormDateStart;

