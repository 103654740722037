import axios from 'axios'
import { Surveys_model } from './surveys_model'
 
const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/user7`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function user_add(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<Surveys_model>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

export function api_add(name:string,value:any) {
  let d= `${API_URL}/${name}`;
  return axios.post<Surveys_model>(d, value)
}


export function delete_api(data:any) {
  let d= `${API_URL}/${data}`;
  
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.delete<any>(d)
}
export function api_put(name:string,value:any,id:number) {
  let d= `${API_URL}/${name}/${id}`;
  return axios.put<Surveys_model>(d, value)
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<Surveys_model>(GET_USER_BY_ACCESSTOKEN_URL)
}
export function get_user(data:any) {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<any>(REGISTER_URL)
}

export function get_data(data:any) {
  let d= `${API_URL}/${data}`;
  
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<any>(d)
}
export function get_data_post(data:any,value:any) {
  let d= `${API_URL}/${data}`; 
  return axios.post<Surveys_model>(d, value)
}
export function get_data_json(data:any) {
  // let d= `/apps/react/json_file/${data}.json`;
  // let d= `/apps/react/json_un/${data}.json`;
  let d= `/json_file/${data}.json`;
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<any>(d)
}

export function FileUpload(data:any) {
  let d= `${API_URL}/${data}`;
  
  const formData = new FormData();
  formData.append("dataFile", data, data.name);
 return axios
    .post(API_URL + "/uploadfile", formData)
    
}
// handle click event of the upload button
 

export const paginationService = {
  getPager,
}
/*
* Get pager related data
*/
function getPager(
  totalItems: number,
  currentPage: number = 1,
  pageSize: number = 10
) {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize)

  // ensure current page isn't out of range
  if (currentPage < 1) {
      currentPage = 1
  } else if (currentPage > totalPages) {
      currentPage = totalPages
  }

  let startPage: number, endPage: number
  if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
  } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
          startPage = 1
          endPage = 10
      } else if (currentPage + 4 >= totalPages) {
          startPage = totalPages - 9
          endPage = totalPages
      } else {
          startPage = currentPage - 5
          endPage = currentPage + 4
      }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
  )

  
  // return object with all pager properties required by the view
  return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
  }
}