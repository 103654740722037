/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import { useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap-v5'
import { SubListAll } from './services/SubListAll'
import Select from 'react-select'
 
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  filed_name: string;
  parentname: string; 
  parentvalue: string;
  parentlabel: string; 
  description: string; 
  descriptionen: string; 
  root_id: string;  
  response7Data:any;
  // Records per page to be shown & loaded
  id: number; 
  data_index: number;
  formik: any;
  ticket?: any;
}

export const FormSelectSearch = ({
  apiRoute,
  response7Data,
  id , 
  parentname, 
  parentvalue, 
  parentlabel, 
  filed_name,
  root_id,
  data_index,
  formik,
  ticket,
}: Props) => {
// class FormSelectSearch extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [isListShow, setListShow] = useState(false);
  
  const [dataList, setList] = useState([] as any);
  const [value_select, SetValueSelect] = useState([] as any);
  const [value_select_old, SetValueSelectOld] = useState({} as any);
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf(); 
  const [recordsPerPage, setRecordsPerPage] = useState(''); 
  const [data_ob7, setDataOb] = useState({} as any);
  
  const [father_key_NO, setfather_key_NO] = useState(0); 
  const [new_value, setnew_value] = useState(0); 
  const [old_id, setOld_id] = useState(0); 
  const [Option, setOption] = useState([] as any);
  
  const [searchText, setsearchText] = useState(''); 
 
  const [lg7Show, setLg7Show] = useState(false);
   
  const load_data = (fa:number,old:number,search_:string) => {
  
    let ib='';
    let data_filter=''; 
    let currentPage=1;
    let sam_type='';
    let skip='1'; 
    let take='20';
    let Url='';
    let download_by_value='';
    let search_root='';
    if(search_)
    {
      search_root=search_;
    }else
    {
      search_root=searchText;
    }
  
     
   if(data_filter)
   ib=JSON.stringify(data_filter);
   get_data(apiRoute+'_select?id='+Url+'&skip=' + skip + '&top=' + take
  + '&filter=' + ib +'&search='+search_root+'&page='+currentPage
  +'&get_states='+sam_type
  +'&father_key='+fa
  +'&download_by_value='+download_by_value
  +'&root_id='+root_id 
  +'&old_id='+old
   )
    .then(data => {
      SetValueSelect(data.data.data)
      if(data.data.check_event.length>0)
      {
        SetValueSelectOld(data.data.check_event[0])
      }
      // setnew_value(old)

 
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => { 

      
   
    })
    
    
  
  }
  
  const handleLgCloseSearch = (event: any) =>
  {
    
    debugger
    setnew_value(event.id)
    SetValueSelectOld(event)
   formik.setFieldValue(filed_name,event.id);
    let s=[];
    let s_object=event;
  //    s= value_select;
  //    s.forEach((element:any) => {
  //  if(element.id==event.value)
  //  {
  //   s_object=element
  //  }
  //  });
  //   debugger
  
    let res={value:event.value,
    key:filed_name,
    s_object:s_object
    }
   
    response7Data(res)
  } 

  const handleLgCloseSearch2 = (event: any) =>
  {
    
    // debugger
    let s=event;
    if(s.length==3)
    {
      let old_id=ticket[filed_name];
      setOld_id(old_id) 
      load_data(0,old_id,event)
    }
    // response7Data(res)
  } 
  const handleLgClose2 = (event: any) =>
  {
    
    debugger
    // setnew_value(event)
    let s=[];
    let s_object;
     s= value_select;
     s.forEach((element:any) => {
   if(element.id==event)
   {
    s_object=element
   }
   });
    debugger
    let res={value:event,
    key:filed_name,
    s_object:s_object
    }
   
    response7Data(res)
  } 
  const edit_data_info = (event: any,value:any) => {
    setDataOb(value)
      // setListShow(true);
    setLg7Show(true);
    
    // if(data_ob.useradded)
    // {
      
    //   setListShow(true);

    // }else
    // {
      
    // setLg7Show(true);

    // }

  };
  const get_sub_data = (event: any,value:any) => {
    
    if(value.id)
    {
      
      setfather_key_NO(value.id)
      let s=father_key_NO
      load_data(value.id,value.id,'') 
    }


  };
  const back_sub_data = (event: any) => {
    
  
      setfather_key_NO(0)
      
      load_data(0,0,'')

    


  };
  const get_search_data = (event: any) => {
    
    if(searchText)
    {
      load_data(0,0,'')

    }


  };
  
  const select_data = (event: any,value:any) => {
    debugger
    if(value.id)
    {

      setnew_value(value.id)
    SetValueSelectOld(value)
   
  formik.setFieldValue(filed_name,value.id); 
        
     


          let s=[];
          let s_object;
           s= value_select;
           s.forEach((element:any) => {
         if(element.id==event)
         {
          s_object=element
         }
         });
          debugger
          let res={value:value.id,
          key:filed_name,
          s_object:s_object
          }
        setLg7Show(false);
         
          response7Data(res)

    }else
    {
      setnew_value(value.p_key)
  formik.setFieldValue(filed_name,value.p_key); 

      let res={value:value.p_key,
        key:filed_name
        }
        
        setListShow(false);
    
          response7Data(res)
    }
   


   

  };
  // const onKeyPress = (event:any) => {
  //   console.log(`key pressed: ${event.key}`);
  //   if(event.key=='F9')
  //   {
  //     edit_data_info(event,data_ob);
  //   }
  // };

  // useKeyPress(['F9', 'b', 'c'], onKeyPress);

  
  const RespFilter_data7 = (e:any) => {
    
    debugger
  };
  useEffect(() => {
    setOption( [
      {label: 'Swedish', value: 'sv'},
      {label: 'English', value: 'en'}
    ])
    let old_id=ticket[filed_name];
    setOld_id(old_id)
    setnew_value(old_id)
    
    load_data(0,old_id,'')
 
   }, []);
   
 
    return (
 
        <div className='col-xl-4'>
 <div  className='row fv-row mb-7'>



     
 <div className='col-xl-9'>
      
      
 <label className='class="form-label fw-bolder text-dark fs-6'> {parentname}
 
 {old_id}
 </label>

  
    {/* <Select options={value_select}  
    value={value_select_old} 
   
       placeholder="اختار" 
    
       onChange={e => {
        // call the built-in handleBur
        handleLgCloseSearch(e)
    
    }}
    onInputChange={e => {
      // call the built-in handleBur
      // handleLgCloseSearch2(e)
 
       
  }}
    
/> */}
     

                  
                    


      <select
        placeholder={parentname}
       name={(filed_name)}
 
        value={new_value}
 
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onChange={(e) =>
          handleLgClose2(e)
        }
      >
        
          
        <option value="0">Select value</option>
 
          
          {value_select && value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 

        </select>

        {/* <select
        placeholder={parentname}
       name={(filed_name)}
 
        value={new_value} 
       
      
        {...formik.getFieldProps(filed_name)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur
          handleLgClose2(e.target.value)
 
          
 
           
      }}
       
      >
        
          
        <option value="0">اختيار القيمة</option>
 
          
          {value_select && value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 

{value_select_old && value_select_old.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 

        </select> */}
      
     
      
{/*       
        <Form.Group>
                      <Form.Control
                        as="select" 
                        name="recordsPerPage"
                        defaultValue={5}
                        onChange={(e) =>
                          handleLgClose2(e)
                        }
                      >
                        <option value="">Select value</option>
                         
          {value_select && value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 
                      </Form.Control>
                    </Form.Group> */}
                       <Modal
      key={1}

        size="lg"
        show={lg7Show}
        onHide={() => setLg7Show(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           اختيار البيانات
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        <br>
        </br>
          
          <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>

     
                <Row>
                <Col md={6} lg={6}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Form.Group>
                      <label className="pr-3">نص البحث</label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        as="input" 
                        name="recordsPerPage" 
                        onChange={(e) =>
                          setsearchText(e.target.value)
                        }
                      >
                      
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col>
                  <Col md={6} lg={6}>
         

      
      <button
            type='button'
            id='kt_sign_up_submit'
            onClick={(e) => get_search_data(e)}
            className='btn btn-lg btn-light w-20 mb-3' 
          >
             <span className='indicator-label'>بحث</span>
           
          </button>

                  </Col>

                  <Col md={6} lg={6}>
                  {father_key_NO &&
    
<a  className='btn btn-back btn-light-primary'
  onClick={(e) => back_sub_data(e)}
  > <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </a>

                  }

                  </Col>
                </Row>
                
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                  <th className='min-w-125px text-center'>الرقم</th>
                <th className='min-w-150px text-center'>الاسم</th>  
 
                <th className='min-w-150px'>action</th> 
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
           
              {value_select && value_select.map((zitem:any, index:number) => {
                      return (
                        
              
                        <tr key={index}>
    <td>
                          <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.id}  
                          </span>
                         </td>

                        <td>
                          <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem?.label}
                          </span>
                         </td>
                 
                           
                        <td>
                      
                      
                            
                  <a className='btn btn-sm btn-primary w-50 mb-5'
                                 onClick={(e) => select_data(e,zitem)}
                   >اختيار</a>

                        </td>
                    
                   
                      </tr>

                  
                        );
                      })} 
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
        </Modal.Body>
      </Modal>
      <Modal
      key={2}
        size="lg"
        show={isListShow}
        onHide={() => setListShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           list
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
       
 
          {/* <SubListAll apiRoute=''
               data_ob={data_ob}
               id={data_ob.p_key}
               responseData={RespFilter_data7}
               TransObject={data_ob}
               root_id={data_ob.useradded}
               get_sub_data={get_sub_data}
select_data={select_data}
                /> */}


        </Modal.Body>
      </Modal>

    </div>
    <div className='col-xl-2'>
     <br></br> 
 
      

        <button
            type='button'
            id='kt_sign_up_submit'
            onClick={(e) => edit_data_info(e,filed_name)}
            className='btn btn-lg btn-light w-20 mb-3' 
          >
             <span className='indicator-label'>اختيار</span>
           
          </button>


</div>
 </div>

        </div>
  
    
    )
    
  
}

// export default FormSelectSearch;

