/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import { useCallback } from 'react';

import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 

import { api_add, FileUpload, get_data, get_data_json, user_add } from '../api'
import { KTSVG } from '../../../../_metronic/helpers'
import { FormSelect } from '../FormSelect'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from '../FileUploadServices'
import { FormTabs } from '../FormTabs' 
import { initialValues } from './CurrenciesModel'

const initialValues7 = {
  companyName: '',
  contactName: '',
  contactTitle: '',
  Address: '',
  city: '',
  region: '',
  postalCode: '',
  country: '',
  phone: '',
  fax: '',
  currency_code:'',
  filed2: '',
  sam_chiled:[]
}
 
 const addCurrenciesSchema = Yup.object().shape({
  
 
 
})

export function AddCurrencies() {
  const d=useHistory();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const params = useParams<any>();
  const [Currencies, setCurrencies] = useState({} as any);
  const [dataList, setList] = useState([] as any);
  const [showAlert, setShowAlert] = useState(false);
  const [ShowTotal, setShowTotal] = useState(false);
   
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  const [SurveyChildren, setSurveyChildren] = useState([] as any);
  const [AddValueTabs, setAddValue] = useState({} as any);
  

  const toggleShowAlert = () => setShowAlert(!showAlert);
  
  useEffect(() => {
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
 
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      
      setCurrencies(data.data.data) ;
      setList(data.data.list) ;
      setSurveyChildren(data.data.children)
        
  
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
   const handleFormTabs = (event: any) =>
{

  if(event.data)
  {
    setAddValue(event)
    if(event.total)
    {
    formik.setFieldValue('total',event.total);

      setShowTotal(true)

    }

  }
  
 
} 
   const AddedDate=new Date().valueOf(); 
  const formik = useFormik({
    initialValues,
    validationSchema: addCurrenciesSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      
      setTimeout(() => {
         values.sam_chiled=[];
          
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          fatherkey:params.fatherkey,
          data_object:AddValueTabs, 
          recipient_id:null
        }
        
        api_add('currencies', sat)
          .then((data:any) => {
            // 
            if(data.data.status)
            {
             
              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
            d.goBack()

            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)

            }
            setLoading(false) 
            // d.goBack()

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            // d.goBack()
            
            setSubmitting(false)
            setStatus('AddCurrencies process has broken')
            setStatus_code('danger')
            setStatus_massag('AddCurrencies process has broken')
          })
      }, 1000)
    },
  })

const CurrenciesAddSchema = Yup.object().shape({
  
   
 




  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('name is required'),  code: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('code is required'),  rate: Yup.number()
    .required('rate is required'),  symbol: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('symbol is required'),
})


  const load_data_ = (fa:number) => {
  
    let ib='';
    let data_filter=''; 
    let currentPage='';
    let sam_type='';
    let skip=''; 
    let take='';
    let Url='';
    let download_by_value='';
    let searchText='';
   if(data_filter)
   ib=JSON.stringify(data_filter);
   get_data('sam_role_select/sam_root_id_filed?id='+Url+'&skip=' + skip + '&top=' + take
  + '&filter=' + ib +'&search='+searchText+'&page='+currentPage
  +'&get_states='+sam_type
  +'&='+fa
  +'&download_by_value='+download_by_value
  +'&root_id=sam_root_id_filed'
   )
    .then(data => {
     // SetValueSelect(data.data.data)
      
      
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
    
  
  }









  
  // const handleKeyPress = useCallback((event) => {
  //   console.log(`Key pressed: ${event.key}`);
  //   if(event.key=='F9')
  //   {
  //     debugger
  //     alert('Woooooooooooooo') ; 
  //   } 
  // }, []);

  // useEffect(() => {
  //   // attach the event listener
  //   document.addEventListener('keydown', handleKeyPress);

  //   // remove the event listener
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, [handleKeyPress]);

  // const onKeyPress = (event) => {
  //   console.log(`key pressed: ${event.key}`);
  // };

  // useKeyPress(['a', 'b', 'c'], onKeyPress);



  return (
   
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'> {Currencies?.title_root} </span> 
         
       
          <span className='card-label fw-bolder fs-3 mb-1'>    اضافة جديد </span> 
 
        </h3>
        <div className='card-toolbar'>
       
          <Link to={"/crafted/Currencies/dashboard/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </Link>
          {/* <Link to="add">new </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table'>
       
        {showAlert && (
          
  <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
  <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
  <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
    <h5 className="mb-1">رسالة</h5>
    <span>{status_massage}.</span>
  </div>

  <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
    <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
  </button>
</div>



        )}
     



          {/* begin::Table */}
          <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
 
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
  







    <div className='row fv-row mb-7'>
  

 
        <div   className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> الاسم</label>
      <input
        placeholder='الاسم'
        type='text' 
        autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
      
          {formik.touched.name && formik.errors.name && (

            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div> 
        <div   className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> code</label>
      <input
        placeholder='code'
        type='text' 
        autoComplete='off'
            {...formik.getFieldProps('code')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.code && formik.errors.code,
              },
              {
                'is-valid': formik.touched.code && !formik.errors.code,
              }
            )}
          />
      
          {formik.touched.code && formik.errors.code && (

            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>      <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>rate</label>
          <input
            placeholder='rate'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('rate')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.rate && formik.errors.rate,
              },
              {
                'is-valid': formik.touched.rate && !formik.errors.rate,
              }
            )}
          />
          {formik.touched.rate && formik.errors.rate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.rate}</span>
              </div>
            </div>
          )}
        </div> 
        <div   className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> symbol</label>
      <input
        placeholder='symbol'
        type='text' 
        autoComplete='off'
            {...formik.getFieldProps('symbol')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.symbol && formik.errors.symbol,
              },
              {
                'is-valid': formik.touched.symbol && !formik.errors.symbol,
              }
            )}
          />
      
          {formik.touched.symbol && formik.errors.symbol && (

            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>      </div>
      {/* end::Form group */}

      <FormTabs   apiRoute={`sam`} id={1}  dataList={SurveyChildren}
                responseDataTabs={handleFormTabs}/>

      {/* end::Form group */}
  

 
      {/* end::Form group */}
 
 
      {/* <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!formik.isValid }
          // disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>حفظ</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
     
      </div> */}
      <div className='text-right' style={{float:"left"}}>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            disabled={!formik.isValid  }
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
          <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
            

            >
              الغاء
            </button>
        </div>
      {/* end::Form group */}
    </form>
      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}
    </div>

  )
}

