/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
let initialValues = {
  Note: '', 
  reason:'',
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  type_accpet_mony: '',
  send_to:0,
  sam_chiled:[]


}


const AccpetMonySchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  respData:any;
  // Records per page to be shown & loaded
  id: number;
  trans_key: number;
  type_accpet_mony:string;

}

export const AccpetMony = ({
  apiRoute,
  respData,
  id ,
  trans_key,
  type_accpet_mony,
}: Props) => {
// class AccpetMony extends React.Component {
  const [title, seTitle] = useState('')
  const [url_, seUrl] = useState('')
   
 
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
 
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf(); 
  useEffect(() => {
  if(type_accpet_mony=='accpet_mony')
  {
    seTitle('هل انت متاكد من ترحيل العملية')
    
    seUrl('sam_role_update_active_mony')
  }else
  {
    seTitle('هل انت متاكد من تنشيط العملية')
    seUrl('sam_role_update_active')

  }
  load_data()
 
   }, []);
   
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: AccpetMonySchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        values.type_accpet_mony=type_accpet_mony;
        
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          trans_key:trans_key,
          
          data_object:'', 
          recipient_id:''
        }
        
      
        api_add(url_+'/'+params.type+'/'+id, sat)
          .then(data => {
            

            setLoading(false)
        respData(false);

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('AccpetMony process has broken')
          })
      }, 1000)
    },
  });
  const load_data = () => {
   

 
  
  }
 
    
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {title}
            {/* { params.id } */}
         
            </span>
            <br/>
            {/* { params.id } */}
           
           </h3>
          <div className='card-toolbar'>
         
       
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
   
            <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
      
        {/* end::Heading */}
  
        {/* begin::Action */}
      
        {/* end::Action */}
 
  
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
  
    
      {/* begin::Form group Firstname */}
  
 
      <div className='row fv-row mb-7'>
        {/* <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'> reason</label>
          <input
            placeholder=' reason'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('reason')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.reason && formik.errors.reason,
              },
              {
                'is-valid': formik.touched.reason && !formik.errors.reason,
              }
            )}
          />
          {formik.touched.reason && formik.errors.reason && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.reason}</span>
              </div>
            </div>
          )}
        </div> */}


        <div className='col-xl-12'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>ملاحضات</label>
         
             <textarea
        placeholder={'ملاحضات'}
       
     
        {...formik.getFieldProps('Note')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched.Note && formik.errors.Note,
          },
          {
            'is-valid': formik.touched.Note && !formik.errors.Note,
          }
        )}
      >
       {formik.getFieldProps('Note').value}
      </textarea>
      {formik.touched.Note && formik.errors.Note && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.Note}</span>
                </div>
              </div>
            )}

          
          </div>
          {/* end::Form group */}
        </div>
      </div>
        {/* end::Form group */}
   
      
        {/* end::Form group */}
  
        {/* begin::Form group */}
        <div className='text-left'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            disabled={formik.isSubmitting || !formik.isValid  }
          >
            {!loading && <span className='indicator-label'>تاكيد</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button> 
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
              onClick={(e) => respData(false)}
            >
              الغاء
            </button>
           
        </div>
        {/* end::Form group */}
      </form>
        
            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default AccpetMony;