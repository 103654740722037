/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Col, Form, InputGroup, Modal, Row, Toast ,Alert} from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { api_add,delete_api, get_data, get_data_json } from '../api'  
import { PaginationFix } from '../paginationFix'

// import { useForm } from "react-hook-form";
import * as Yup from 'yup'
import clsx from 'clsx' 
import { useParams,useHistory} from 'react-router-dom' 
import { Accpet } from '../Accpet'
import { AccpetMony } from '../AccpetMony'
import { ListsInfo } from '../info_list'
import { ObjectInfo } from '../info'
import { TransServices } from '../trans/trans_services'
import { ObjectInfoPrint } from '../info_print'
import SelectSearch from 'react-select-search'


import ReactToPrint from 'react-to-print'
 
import { CurrenciesEdit } from './CurrenciesEdit'
// import { EditOrder } from './EditOrder'
import { CurrenciesFilter } from './CurrenciesFilter'

 
type Props = {
  className: string
}

const loginSchema = Yup.object().shape({
 

})
 
const initialValues = {
  searchText: '',
  fatherkey: 0,
  get_states: 0,
  
}
const INITIAL_STATE = [{ 
  id: 0,
  username: '',
  password: '',
  email: '',
  firstname: '',
  lastname: ''
}];


const ListCurrencies: React.FC<Props> = ({className}) => {

  const [type_id, setType] = useState(0); 
  const [type_fatherkey, setTypefatherkey] = useState(0); 
  const params = useParams<any>();
  const [Data, setData] = useState([]);
  const [isLoadingData, setLoadingData] = useState(false);
 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  const d=useHistory();
   
   const [recordsPerPage, setRecordsPerPage] = useState(10); 
   const [isSearchingData, setSearchingData] = useState(false);
   const [reload, setReload] = useState(false);
   const [search, setSearch] = useState({} as any);
   const [page_number, Setpage_number] = useState(7);
   
   const [data_ob, setDataOb] = useState({} as any);
   const [TransInfo, setDataTransInfo] = useState({} as any);
   const [Option, setOption] = useState([] as any);
   
   const [Currencies, setCurrencies] = useState({} as any);
   const [role_list, setRole_list] = useState({} as any);
   const [dataList, setList] = useState([] as any);
   const [dataListView, setListView] = useState([] as any);
   const [dataListInfo, setListInfo] = useState([] as any);
   const [children, setchildren] = useState([] as any);
   const [EditList, setEditList] = useState([] as any);
   
   const [type_accpet, setType_accpet] = useState('accpet');

  const load_data = () => {
    
    setData([])
    setCurrencies({}) ;

    Setpage_number(0)
  
if(params.fatherkey)
{
  let s=initialValues;
    s.fatherkey=params.fatherkey;

    setSearch(s)
    
}else
{
  let s=initialValues;
  s.fatherkey=0;
  setSearch(s)
}
setOption( [
  {name: 'Swedish', value: 'sv'},
  {name: 'English', value: 'en'},
  {
      type: 'group',
      name: 'Group name',
      items: [
          {name: 'Spanish', value: 'es'},
      ]
  }
])
    setReload(true);
    setTimeout(() => {
      setReload(false);
      Setpage_number(7)

    }, 1000);
    get_data_json('data_'+params.type )
    // get_data('sam_role_view/'+params.type )
    .then(data => {
      
  formik.setFieldValue('searchText','');

     setCurrencies(data.data.data) ;
     setList(data.data.list) ;
    //  setRole_list(data.data.data.role_list)
     setchildren(data.data.data.children)
     let d=data.data.list;
       
     if(d.length>0)
     {
     let data_list_view = d.slice(0,6); 
     setListView(data_list_view)
     }
    load_Role_list()
     
      setLoading(false)
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
      setLoading(false)
   
    })
  };

  
  const load_data7 = () => {
    
    
      fetch('manifest.json',
    {
      headers:{
        'Content-type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function(res){
      console.log(res)
      res.json();
    }).then(function(my){
      console.log(my)
    })

    
    get_data_json(params.type )
    .then(data => {
      
    }).catch((error) => {
      console.log(error)

       
   
    })

  };
  const load_Role_list = () => {

  get_data('sam_role_access/'+params.type )
    .then(data => {
      
     setRole_list(data.data.data); 
    
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => { 
   
    })
  };

  const load_data_list = () => {
    
    setData([])
    setCurrencies({}) ;
    Setpage_number(0)
   
    setReload(true);
    setTimeout(() => {
      setReload(false);
    Setpage_number(7)

    }, 1000);
    get_data('sam_role_view/'+params.survey_id )
    .then(data => {
     setCurrencies(data.data.data) ;
     setList(data.data.list) ;
     setRole_list(data.data.data.role_list);
     setchildren(data.data.data.children)

     let d=data.data.list;
       
     if(d.length>0)
     {
     let data_list_view = d.slice(0,5); 
     setListView(data_list_view)
     }
     
      setLoading(false)
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
      setLoading(false)
   
    })
  };
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // setLoading(false)
      Setpage_number(0)
    // 

      setSearch(values)
      
if(values.searchText=='')
{
  setReload(true);
  setTimeout(() => {
    setReload(false); 

  }, 1000);
}
    
      
      setTimeout(() => {
      Setpage_number(7)
         
      
      }, 1000)
    },
  })

  const [loading, setLoading] = useState(false)

  
  const [showA, setShowA] = useState(true); 
  const [showFilter, setShowFilter] = useState(false); 
  const [LgShowOrder, setLgShowOrder] = useState(false); 
 
  const [filter_data, setFilter_data] = useState(''); 


  const toggleShowA = () => setShowA(!showA);
  const toggleShowFilter = () => setShowFilter(!showFilter);
  
  const [lgShow, setLgShow] = useState(false);
  const [isAccpetShow, setAccpetShow] = useState(false);
  const [isTransShow, setTransShow] = useState(false);
  const handleTransShow= () =>{
    setTransShow(false);
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1000);
  } 
  const handleAccpetShow= () =>{
    setAccpetShow(false);
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1000);
  } 

  const [isInfoShow, setInfoShow] = useState(false);
 
  const handleInfoShow= () => setInfoShow(false);

  const [isInfoListShow, setInfoListShow] = useState(false);
 
  const handleInfoListShow= () => setInfoListShow(false);

  // const { slice, range } = useTable(data, page, rowsPerPage);
  if(params.survey_id&&params.subid)
  {
    
    if(type_id!=params.survey_id)
    {
    setType(params.survey_id)
 
    load_data_list()
    console.log('useEffect 2');

    }
    
  }else if(params.type&&params.fatherkey)
  {
     
    if(type_fatherkey!=params.fatherkey&&params.fatherkey>1)
    {
    setTypefatherkey(params.fatherkey)
    formik.setFieldValue('searchText','');
  
    load_data()
    console.log('useEffect fatherkey');

    }
    
    
  }else if(params.type&&type_fatherkey)
  {

    if(params.type==type_id&&type_fatherkey)
    {
    setTypefatherkey(0)
    formik.setFieldValue('searchText','');
    setType(params.type)

    load_data()
    console.log('useEffect 2');

    }
  }else if(params.type)
  {

    if(params.type!=type_id)
    {
      
    setTypefatherkey(0)
    formik.setFieldValue('searchText','');
    setType(params.type)

    load_data()
    console.log('useEffect 2');

    }
  } 
 

 const [show, setShow] = useState(false);

 const handleLgClose = () =>
 {
  setLgShow(false);
  setReload(true);
  setTimeout(() => {
    setReload(false);
  }, 1000);
 } 

  // const { register, handleSubmit, errors } = useForm();
  
 
  /*
   * Clear search results
   * Make search object empty if no value
   * Trigger reload in pagination
   */
  const clearSearchResults = (event: any) => {
    if (!event.target.value && search) {
      setSearch({
        term: "",
      });
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 1000);
    }
  };

  useEffect(() => {
    console.log('useEffect useEffect useEffect ');
    // if(params.type )
    // {
    //   load_data();

    // }
 
   }, []);
 


  const delete_data = (event: any,id:number) => {
    
   
    handleClose()
    delete_api('Data/'+id)
    .then(data => {
      
       
 
      setLoading(false)
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 1000);
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
      setLoading(false)
   
    })
  };
  
  
  const RespFilter_data = (e:any) => {
  formik.setFieldValue('searchText','');
    
    setFilter_data(e)
    toggleShowFilter()
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1000);
  };
  const delete_data_info = (event: any,value:any) => {
    
 
    setShow(true);
    setDataOb(value)
  };
  const edit_data_info = (event: any,value:any) => {
    
    setDataOb(value)
    setLgShow(true);

  };
  const edit_order_data_info = (event: any,value:any) => {
    
    setDataOb(value)

    setLgShowOrder(true);

  };
  const accpet_data_info = (event: any,value:any,type:any) => {
    
    setType_accpet(type)
    setDataOb(value)
    setAccpetShow(true); 
  
  };
  
  const object_data_info = (event: any,value:any,type:any) => {
    
    setDataOb(value)
    // setAccpetShow(true);
    setInfoShow(true);
  
  };
  const clearSearchCheck = (event: any,item:any,name_key:any,index:any) => {
    
    let v=event.target.value; 
    let d=item;
    d[name_key]=event.target.value;
    d['quantity']=event.target.value;
    EditList[index]=d;
    
 
    // let ewe=[];
    // if(EditList.length>0)
    // {
    //   EditList.forEach((element:any) => {
    //     if(element.==d.)
    //     {
    //  ewe.push(d)
         
    //     }else
    //     {
    //  ewe.push(element)
 
    //     }
           
    //      });
     
    // }else
    // {
    // ewe.push(d)

    // }
 
    //  setEditList(ewe)
    let da=EditList;

    let d2=da;
    
  };

  const sam_role_update_priority = () => {

    let sat={
      data:JSON.stringify(EditList)
    }

    api_add('sam_role_update_priority/'+params.type,sat )
      .then(data => {
         
      
        // dispatch(auth.actions.login(accessToken))
      })
      .catch(() => { 
     
      })
    };
  
  const list_data_info = (event: any,value:any,type:any) => {
    
    setDataOb(value)

    get_data('getAllsub_activities/'+value.trans_key )
    .then(data => { 
      
     setListInfo(data.data.data) ;
  
      setInfoListShow(true);
 
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => { 
   
    })
    // setAccpetShow(true);
  
  };
  
  const trans_data_info = (event: any,value:any,TransInfo:any) => {
    
    setDataOb(value)
    setDataTransInfo(TransInfo)
    

    setTransShow(true);
     
    // setAccpetShow(true);
  
  };

  const componentRef=useRef(null as any);
  

  return (
    
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>  
          {Currencies.title_root}
          
          </span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
        </h3>
        {role_list && role_list.add &&(

          
        <div className='card-toolbar'> 
        {params.fatherkey && (
          <Link to={params.fatherkey+"/add"} className='btn btn-sm btn-light-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
           اضافة جديد
             </Link> 
        )}
 {!params.fatherkey && (
      <Link to={params.type+"/add"} className='btn btn-sm btn-light-primary'>
      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
       اضافة جديد
         </Link>

         
        )}

      
     
        </div>
         )}
    <Link to={"currencies/add"} className='btn btn-sm btn-light-primary'>
      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
       اضافة جديد
         </Link>

         
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}
          
     
          {/* <Row>
      <Col md={6} className="mb-2">
        <Button onClick={toggleShowA} className="mb-2">
          Toggle Toast <strong>with</strong> Animation
        </Button>
        <Toast show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
      </Col>
      </Row> */}
  
  <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div className="flex-grow-1 filter-container-c">
          <div className="search">
            {/* <Form onSubmit={handleSubmit(setSearch)}> */}
{/* <SelectSearch options={Option} value="sv" placeholder="Choose your language" /> */}
    
       
  
            <form
      className='form w-50'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
  
      {/* begin::Heading */}

 

      {/* begin::Form group */}
      
      <Row>
                <Col md={6} lg={6}>
                  <InputGroup>
                  <div className='fv-row mb-2'>
 
        <input
          placeholder='نص البحث'
          {...formik.getFieldProps('searchText')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.searchText && formik.errors.searchText},
            {
              'is-valid': formik.touched.searchText && !formik.errors.searchText,
            }
          )}
          type='text'
          name='searchText'
          autoComplete='off'
        />
        {formik.touched.searchText && formik.errors.searchText && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.searchText}</span>
          </div>
        )}
      </div>
      <div>
        
        </div>
                    <span>
                      <i className="bx bx-fw bx-search"></i>
                    </span>
                    <InputGroup>
                    <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm btn-primary w-50 mb-5'
          disabled={isSearchingData }
        >
          {!loading && <span className='indicator-label'>بحث</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
       
 

        <a className='btn btn-sm btn-light w-50 mb-5'
                     
                        onClick={toggleShowFilter}
                      >filter</a>
                    </InputGroup>
                  </InputGroup>
                </Col>
                {/* <Col md={6} lg={6}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Form.Group>
                      <label className="pr-3">Records per page</label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        as="select" 
                        name="recordsPerPage"
                        defaultValue={10}
                        onChange={(e) =>
                          setRecordsPerPage(parseInt(e.target.value))
                        }
                      >
                      <option value="">Select value</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col> */}
              </Row>
      {/* end::Form group */}


      {/* end::Form group */}

 
      {/* end::Action */}
    </form>

            
          </div>
        </div>
      </div>
      <div >
      {/* <ReactToPrint 
     trigger={()=> <button> print this out</button>}
     content={()=> componentRef.current}
     copyStyles={true}

    //  pageStyle={pageStyle}
/> */}
     
          {showFilter && (
        
        <CurrenciesFilter
       
    apiRoute=''
    dataObject={Currencies}
    responseData={RespFilter_data}
    dataList={Currencies?.sam_search}
    dataList2={dataListView}
     ></CurrenciesFilter>
 
      )}
   
          </div>
   
      <Row>
        <Col md={12} lg={12}>
       
          <Card>
            <Card.Body className="pt-0">
              {/* <Table striped hover>
                <thead>
                  <tr>
                    <th className="bt-none">Name</th>
                    <th className="bt-none">Email</th>
                    <th className="bt-none">Department</th>
                  </tr>
                </thead>   
              </Table> */}
 
              <table className='table align-middle gs-0 gy-4' ref={componentRef}
    dir="rtl" >
            {/* begin::Table head */}

       <thead>
              <tr className='fw-bolder text-muted bg-light'>





                        <th className='min-w-125px text-center' >الرقم</th>
                                                <th className='min-w-125px text-center' >الاسم</th>
                                                <th className='min-w-125px text-center' >code</th>
                                                <th className='min-w-125px text-center' >rate</th>
                             <th className='min-w-150px text-center'>action</th> 
                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
                          </thead>
            {/* end::Table head */}




   {/* begin::Table body */}
            <tbody>
         

            {!Data.length && !isLoadingData && (
                    <tr className="text-center">
                      <td colSpan={3}>No Data</td>
                    </tr>
                  )}
                  {isLoadingData && (
                    <tr className="text-center">
                      <td colSpan={3}>
                        <span>Loading...</span>
                      </td>
                    </tr>
                  )}
                        {!isLoadingData &&Data.map((item:any, index:number) => {
                      return (
                        <tr
                        key={index}
                        >

         <td key={index}>
          
                      <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                       {item?.id}
                      </span>
               
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Paid</span> */}
           </td>           <td key={index}>
          
                      <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                       {item?.name}
                      </span>
               
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Paid</span> */}
           </td>           <td key={index}>
          
                      <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                       {item?.code}
                      </span>
               
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Paid</span> */}
           </td>           <td key={index}>
          
                      <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                       {item?.rate}
                      </span>
               
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Paid</span> */}
           </td>     <td    >

                         <Link to={params.type+`/info/${item.id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />

      </Link>          
     

                        {children && children.length>0 &&(
                <Link to={'/crafted/surveys/dashboard/'+params.type+`/list/${item.id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <KTSVG path='/media/icons/duotune/art/art009.svg' className='svg-icon-3' />

                  </Link>
                )}      
         {Currencies && Currencies.fatherkey &&(
               <Link to={'/crafted/surveys/dashboard/'+params.type+`/chiled/${item.id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
               <KTSVG path='/media/icons/duotune/art/art007.svg' className='svg-icon-3' />
                 </Link>
                )}    
  {Currencies && Currencies.titleen=='categories' &&(
               <Link to={'/crafted/surveys/dashboard/'+params.type+`/chiled/${item.id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
               <KTSVG path='/media/icons/duotune/art/art007.svg' className='svg-icon-3' />
                 </Link>
                )}
                           

 
                            

                                {/* <button onClick={(e) => delete_data_info(e,item)} className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm"  disabled={item.isactive}>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                      </button> */}
                                
   



 
 
        <a  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={(e) => object_data_info(e,item,'s')}
        >
    <KTSVG path='/media/icons/duotune/art/art002.svg' className='svg-icon-3' />

      </a>
      

      {Currencies.ztype!='Financial' && (
         <>
                    {role_list && role_list.update &&(
    <Link to={params.type+`/edit/${item.id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />

      </Link>
      
  
                )}

                                {role_list && role_list.update &&(
                                  <>
  <Button  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={(e) => edit_data_info(e,item)}>
  <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
  
        </Button>

        {/* <Button  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={(e) => edit_order_data_info(e,item)}>
  <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
  
        </Button> */}

        </>
                )}

         </>
                  )}

                       {!item.isactive && Currencies.ztype=='Financial' && (

                        <>
                                   {role_list && role_list.update &&(
    <Link to={params.type+`/edit/${item.id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />

      </Link>
      
  
                )}

                                                        {/* {role_list && role_list.update &&(
                                  <>
  <Button  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={(e) => edit_data_info(e,item)}>
  <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
  
        </Button>

        <Button  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={(e) => edit_order_data_info(e,item)}>
  <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
  
        </Button>

        </>
                )} */}
                   <a
                        
                        className='btn btn-sm btn-primary'
                     
                        onClick={(e) => accpet_data_info(e,item,'accpet_mony')}
                      >
                          ترحيل
                      </a>   
                      <a
                        
                        className='btn btn-sm btn-danger'
                     
                        onClick={(e) => accpet_data_info(e,item,'accpet')}
                      >
                          الغاء
                      </a>  
                        </>
     
                  )}

{!item.isactive && Currencies.ztype!='Financial' && (
        <a
                        
        className='btn btn-sm btn-primary'
     
        onClick={(e) => accpet_data_info(e,item,'accpet')}
      >
          تنشيط
      </a>   
                  )}




                  {/* return start */}
                  {(Currencies.titleen=='bills1' || Currencies.titleen=='invoices1') && (
         <Link to={params.type+`/return/${item.id}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
         {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
             

             <a
                        
                        className='btn btn-sm btn-danger btn-active-light'

                      >
                          مرتجع
                      </a>  

           </Link> 
                  )}



{Currencies.titleen=='contacts' && (
         <Link to={params.type+`/list_trans/${item.id}`}  >
         {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
             <a
              className='btn btn-sm btn-danger btn-active-light'

                      >
                          كشف الحساب
                      </a>  
           </Link> 
                  )}

                  {/* return end */}
{/* <span className='text-muted fw-bold text-muted d-block fs-7'>{item?.trans_key}</span>
<span className='text-muted fw-bold text-muted d-block fs-7'>{item?.trans_level}</span>
<span className='text-muted fw-bold text-muted d-block fs-7'>{item?.trans_time}</span>
             */}
                            </td>
                         
                        </tr>
                      )
                    })} 

           
  
             
            </tbody>
            {/* end::Table body */}

      {/* end::Table body */}
          </table>

              <PaginationFix
                apiRoute={`currencies`}
                recordsPerPage={recordsPerPage}
                responseData={setData}
                isLoadingData={setLoadingData}
                reloadApi={reload}
                search={search}
                father_key={params.fatherkey}
                filter_data={filter_data} 
                isSearchingData={setSearchingData}
                page_number={page_number}
            
              /> 
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>

      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>

  
 
      
          </div>
  

      </div>
      {/* begin::Body */}

      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton className='btn btn-sm btn-danger'>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body> */}

        <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
  <button type="button" className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger" data-bs-dismiss="alert">
    <span className="svg-icon svg-icon-1">...</span>
  </button>

  <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">...</span>

  <div className="text-center">
    <h5 className="fw-bolder fs-1 mb-5">This is an alert</h5>

    <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

    <div className="mb-9">
      The alert component can be used to highlight certain parts of your page for <strong>higher content visibility</strong>.<br/>
      Please read our <a href="#" className="fw-bolder me-1">Terms and Conditions</a> for more info.
    </div>
    <div className="d-flex flex-center flex-wrap">
      <Button className="btn btn-outline btn-outline-danger btn-active-danger m-2" onClick={handleClose}>Cancel</Button>
      <Button className="btn btn-danger m-2"
           onClick={(e) => delete_data(e,data_ob.id)}>Ok, I got it</Button>
    </div>
  </div>





</div>

        {/* <p>هل انت متاكد من حذف
        <br>
        </br>
          {data_ob.id}
          {data_ob.name}
        </p> */}
     
        {/* </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            الغاء
          </Button>
          <Button variant="primary" 
              onClick={(e) => delete_data(e,data_ob.id)}
              >
            نعم
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal
      key={1}

        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           تعديل البيانات
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.id} 
        <br>
        </br>
          {data_ob.name}

        <CurrenciesEdit apiRoute={`sam`} id={data_ob.id} 
                responseData={handleLgClose}
        
        />

        </Modal.Body>
      </Modal>
      
    
  
      <Modal
      key={2}
        size="lg"
        show={isAccpetShow}
        onHide={() => setAccpetShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           التاكيد
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.id} 
        <br>
        </br>
          {data_ob.name}

        <AccpetMony apiRoute={`sam`} id={data_ob.id} 
          trans_key={data_ob.trans_key} 
                respData={handleAccpetShow}
                type_accpet_mony={type_accpet} 
        
        />

        </Modal.Body>
      </Modal>

      <Modal
      key={2}
        size="lg"
        show={isTransShow}
        onHide={() => setTransShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Accpet Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.id} 
        <br>
        </br>
          {data_ob.name}

        
            
      <TransServices
     panelName={TransInfo.action_type}
     data_ob={data_ob}
     Surveys={TransInfo}
     RespFilter_data={handleTransShow}>
       
     </TransServices>

        </Modal.Body>
      </Modal>
  
      <Modal
      key={2}
        size="lg"
        show={isInfoShow}
        onHide={() => setInfoShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          info
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.id} 
        <br>
        </br>
          {data_ob.name}

          <ObjectInfo className='card-xxl-stretch' data_object={data_ob} dataList={dataList}
             respData={setInfoShow} />

       

        </Modal.Body>
      </Modal>
      
      <Modal
      key={2}
        size="lg"
        show={isInfoListShow}
        onHide={() => handleInfoListShow()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Accpet Modal
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {data_ob.id} 
        <br>
        </br>
          {data_ob.name}

          <ListsInfo  className='card-xxl-stretch' data={dataListInfo}
            respData={handleInfoListShow}
            />
       

        </Modal.Body>
      </Modal>
    </div>
    
  )
}

export {ListCurrencies}
function Currencies_model(Currencies_model: any): [any, any] {
  throw new Error('Function not implemented.')
}

