/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 

import { api_put, get_data } from '../api'
import { KTSVG } from '../../../../_metronic/helpers'
import { initialValues } from './CurrenciesModel'
 



const CurrenciesEditSchema = Yup.object().shape({
  
   
 




  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('name is required'),  code: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('code is required'), 
  rate: Yup.number()
    .required('rate is required'),  symbol: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('symbol is required'),
})


interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseData:any;
  // Records per page to be shown & loaded
  id: number;


}

export const CurrenciesEdit = ({
  apiRoute,
  responseData,
  id ,

}: Props) => {
// class Edit extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)

  useEffect(() => {
    if(id)
    {
 
     get_data('currencies/'+id)
     .then(data => {
       
       debugger
       setModel(data.data.data)
      // initialValues=data.data.data
 
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
  const dispatch = useDispatch()
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: CurrenciesEditSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        
        api_put('currencies', values,id)
          .then(data => {
            

            setLoading(false)
        responseData(false);

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Edit process has broken')
          })
      }, 1000)
    },
  });

  
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>edit Arrivals
            {/* { params.id } */}
         
            </span>
            <br/>
            {/* { params.id } */}
           
            <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span>
          </h3>
          <div className='card-toolbar'>
         
            <Link to="/add" className='btn btn-sm btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              New Member </Link>
            {/* <Link to="add">new </Link> */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
   
            <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='mb-10 text-center'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>
            
           edit 
            {model.name}
            
            </h1>
          {/* end::Title */}
   
        </div>
        {/* end::Heading */}
  
        {/* begin::Action */}
      
        {/* end::Action */}
  
        <div className='d-flex align-items-center mb-10'>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>
  
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}



    <div className='row fv-row mb-7'>
  

      <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>الاسم</label>
          <input
            placeholder='الاسم'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>      <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>code</label>
          <input
            placeholder='code'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('code')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.code && formik.errors.code,
              },
              {
                'is-valid': formik.touched.code && !formik.errors.code,
              }
            )}
          />
          {formik.touched.code && formik.errors.code && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.code}</span>
              </div>
            </div>
          )}
        </div>      <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>rate</label>
          <input
            placeholder='rate'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('rate')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.rate && formik.errors.rate,
              },
              {
                'is-valid': formik.touched.rate && !formik.errors.rate,
              }
            )}
          />
          {formik.touched.rate && formik.errors.rate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.rate}</span>
              </div>
            </div>
          )}
        </div>      <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>symbol</label>
          <input
            placeholder='symbol'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('symbol')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.symbol && formik.errors.symbol,
              },
              {
                'is-valid': formik.touched.symbol && !formik.errors.symbol,
              }
            )}
          />
          {formik.touched.symbol && formik.errors.symbol && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.symbol}</span>
              </div>
            </div>
          )}
        </div>      </div>
      {/* end::Form group */}


        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid  }
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
        
            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}



