/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'
import { Card4 } from '../../../_metronic/partials/content/cards/Card4'
import { get_data } from './api'
 
type Props = {
  className: string,
  data_object:any,
dataList:any,
respData:any,

}
   
const Show_file: React.FC<Props> = ({className,
    data_object,
    dataList,respData}) => {

      const [dataListInfo, setListInfo] = useState([] as any);

      useEffect(() => {
        if(data_object.p_key )
        {
     
         get_data('list_file/'+data_object.p_key )
         .then(data => {
          setListInfo(data.data.data) ;
           
    
           // dispatch(auth.actions.login(accessToken))
         })
         .catch(() => { 
        
         })
        }
     
       }, []);
       
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
    <div className='card-header cursor-pointer'>
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>الملفات</h3>
      </div>

     
    </div>

    <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='min-w-125px'>الرقم</th> 
                <th className='min-w-150px'>اسم الملف</th> 
                {/* <th className='min-w-125px'>التاريخ</th>  */}
                <th className='min-w-150px'>ملاحضة</th> 
                <th className='min-w-150px'>action</th> 
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
           
              {dataListInfo && dataListInfo.map((zitem:any, index:number) => {
                      return (
                        
              
                        <tr key={index}>
 
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.id}
                          </span>
                         </td>
                          
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.title} 
                          </span>
                         </td>
 
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.note}
                          </span>
 
                        </td>
                        <td>
                     
                     
                          <div className='col-12 col-sm-12 col-xl'>
                          <div className='card h-100'>
      <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
        <a href={zitem.file_path_url} className='text-gray-800 text-hover-primary d-flex flex-column'>
          <div className='symbol symbol-75px mb-6'>
            <img src={toAbsoluteUrl('/media/svg/files/pdf.svg')} alt='' />
          </div>
          <div className='fs-5 fw-bolder mb-2'>{zitem.title}</div>
        </a>
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>{zitem.note}</div>
      </div>
    </div>
          {/* <Card4 icon='/media/svg/files/pdf.svg' title='Project Reqs..' description='3 days ago' /> */}
        </div>
                        </td>
                   
                      </tr>

                  
                        );
                      })} 
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
    <div className='text-right'>
        
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-20 mb-3'
          onClick={(e) => respData(false)}
        >
          الغاء
        </button>
       
    </div>
  </div>

  )
}

export {Show_file}
