/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import { useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap-v5'
import { SubListAll } from './services/SubListAll'
 
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  response7Data:any;
  // Records per page to be shown & loaded
  id: number;
  data_ob: any; 
  data_survey: any; 
  data_index: number;
  formik: any;
}

export const FormSelect = ({
  apiRoute,
  response7Data,
  id ,
  data_ob, 
  data_survey,
  data_index,
  formik,
}: Props) => {
// class FormSelect extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [isListShow, setListShow] = useState(false);
  
  const [dataList, setList] = useState([] as any);
  const [value_select, SetValueSelect] = useState([] as any);
  const [value_select_old, SetValueSelectOld] = useState([] as any);
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf(); 
  const [recordsPerPage, setRecordsPerPage] = useState(''); 
  const [data_ob7, setDataOb] = useState({} as any);
  
  const [father_key_NO, setfather_key_NO] = useState(0); 
  const [new_value, setnew_value] = useState(0); 
  
  const [searchText, setsearchText] = useState(''); 

  const [lg7Show, setLg7Show] = useState(false);
   
  const load_data = (fa:number) => {
  
    let ib='';
    let data_filter=''; 
    let currentPage='';
    let sam_type='';
    let skip=''; 
    let take='';
    let Url='';
    let download_by_value='';
    let old=0;
  
     
   if(data_filter)
   ib=JSON.stringify(data_filter);
   get_data('sam_role_select/'+data_ob.root_id+'?id='+Url+'&skip=' + skip + '&top=' + take
  + '&filter=' + ib +'&search='+searchText+'&page='+currentPage
  +'&get_states='+sam_type
  +'&father_key='+fa
  +'&download_by_value='+download_by_value
  +'&root_id='+data_ob.root_id 
  +'&old_id='+old

   )
    .then(data => {
      SetValueSelect(data.data.data)
      
      SetValueSelectOld(data.data.check_event)
      // dispatch(auth.actions.login(accessToken))
    })
    .catch(() => {
    
   
    })
    
  
  }

  const handleLgClose2 = (event: any) =>
  { 
    setnew_value(event.target.value)

    let res={value:event.target.value,
    key:data_ob.status_type
    }
   
    response7Data(res)
  } 
  const edit_data_info = (event: any,value:any) => {
    setDataOb(value)
    
    if(data_ob.useradded)
    {
      
      setListShow(true);

    }else
    {
      
    setLg7Show(true);

    }

  };
  const get_sub_data = (event: any,value:any) => {
    
    if(value.id)
    {
      
      setfather_key_NO(value.id)
      let s=father_key_NO
      load_data(value.id) 
    }


  };
  const back_sub_data = (event: any) => {
    
  
      setfather_key_NO(0)
      
      load_data(0)

    


  };
  const get_search_data = (event: any) => {
    
    if(searchText)
    {
      load_data(0)

    }


  };
  
  const select_data = (event: any,value:any) => {
    
    if(value.id)
    {
      setnew_value(value.id)
  formik.setFieldValue(data_ob.status_type,value.id); 

      let res={value:value.id,
        key:data_ob.status_type
        }
        debugger
        setLg7Show(false);
    
          response7Data(res)
    }else
    {

      setnew_value(value.p_key)
  formik.setFieldValue(data_ob.status_type,value.p_key); 

      let res={value:value.p_key,
        key:data_ob.status_type
        }
        debugger
        setListShow(false);
    
          response7Data(res)
    }
   


   

  };
  // const onKeyPress = (event:any) => {
  //   console.log(`key pressed: ${event.key}`);
  //   if(event.key=='F9')
  //   {
  //     edit_data_info(event,data_ob);
  //   }
  // };

  // useKeyPress(['F9', 'b', 'c'], onKeyPress);

  
  const RespFilter_data7 = (e:any) => {
    
    debugger
  };
  useEffect(() => {
    load_data(0)
 
   }, []);
   
 
    return (
 
        <div className='col-xl-4'>
 <div  className='row fv-row mb-7'>
 <div className='col-xl-9'>
      
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      {/* <select
        placeholder={data_ob.title}
       name={(data_ob.status_type)}
 
        value={new_value}
 
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onChange={(e) =>
          handleLgClose2(e)
        }
      >
        
          
        <option value="0">Select value</option>
 
          
          {value_select && value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 

        </select> */}
     
        <select
        placeholder={data_ob.title}
       name={(data_ob.status_type)}
 
        value={new_value}
 
       
      
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      >
        
          
        <option value="0">اختيار القيمة</option>
        
          
          {value_select && value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 

 
{value_select_old && value_select_old.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 
        </select>
      
     
      
{/*       
        <Form.Group>
                      <Form.Control
                        as="select" 
                        name="recordsPerPage"
                        defaultValue={5}
                        onChange={(e) =>
                          handleLgClose2(e)
                        }
                      >
                        <option value="">Select value</option>
                         
          {value_select && value_select.map((plan:any, index:number) => {
                      return (
 
                        <option key={index} value={plan.id} >{plan.label}</option>

                        );
                      })} 
                      </Form.Control>
                    </Form.Group> */}
                       <Modal
      key={1}

        size="lg"
        show={lg7Show}
        onHide={() => setLg7Show(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           اختيار البيانات
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
        {/* {data_ob7.id}  */}
        <br>
        </br>
          {/* {data_ob7.name} */}

          
          <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>

     
                <Row>
                <Col md={6} lg={6}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Form.Group>
                      <label className="pr-3">نص البحث</label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        as="input" 
                        name="recordsPerPage" 
                        onChange={(e) =>
                          setsearchText(e.target.value)
                        }
                      >
                      
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col>
                  <Col md={6} lg={6}>
         

      
      <button
            type='button'
            id='kt_sign_up_submit'
            onClick={(e) => get_search_data(e)}
            className='btn btn-lg btn-light w-20 mb-3' 
          >
             <span className='indicator-label'>بحث</span>
           
          </button>

                  </Col>

                  <Col md={6} lg={6}>
                  {father_key_NO &&
    
<a  className='btn btn-back btn-light-primary'
  onClick={(e) => back_sub_data(e)}
  > <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </a>

                  }

                  </Col>
                </Row>
                
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                  <th className='min-w-125px text-center'>الرقم</th>
                <th className='min-w-150px text-center'>الاسم</th>  
 
                <th className='min-w-150px'>action</th> 
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
           
              {value_select && value_select.map((zitem:any, index:number) => {
                      return (
                        
              
                        <tr key={index}>
    <td>
                          <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.id}  
                          </span>
                         </td>

                        <td>
                          <span className='text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem?.label}
                          </span>
                         </td>
                 
                           
                        <td>
                      
                      
                                  <a className='btn btn-sm btn-light w-50 mb-5'
                                 onClick={(e) => get_sub_data(e,zitem)}
                   >sub</a>
                  <a className='btn btn-sm btn-primary w-50 mb-5'
                                 onClick={(e) => select_data(e,zitem)}
                   >select</a>

                        </td>
                    
                   
                      </tr>

                  
                        );
                      })} 
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
        </Modal.Body>
      </Modal>
      <Modal
      key={2}
        size="lg"
        show={isListShow}
        onHide={() => setListShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           list
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...
       
 
          <SubListAll apiRoute=''
               data_ob={data_ob}
               id={data_ob.p_key}
               responseData={RespFilter_data7}
               TransObject={data_ob}
               root_id={data_ob.useradded}
               get_sub_data={get_sub_data}
select_data={select_data}
                />


        </Modal.Body>
      </Modal>

    </div>
    <div className='col-xl-2'>
     <br></br> 
 
      

        <button
            type='button'
            id='kt_sign_up_submit'
            onClick={(e) => edit_data_info(e,data_ob)}
            className='btn btn-lg btn-light w-20 mb-3' 
          >
             <span className='indicator-label'>اختيار</span>
           
          </button>


</div>
 </div>

        </div>
  
    
    )
    
  
}

// export default FormSelect;

