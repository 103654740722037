/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { KTSVG } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'
 
type Props = {
  className: string,
  data_object:any,
dataList:any,
respData:any,

}

const ObjectInfo: React.FC<Props> = ({className,
    data_object,
    dataList,respData}) => {
  const componentRef=useRef(null as any);

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' ref={componentRef}
    dir="rtl" 
    >
    <div className='card-header cursor-pointer'>
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>التفاصيل</h3>
      </div>
      <ReactToPrint 
     trigger={()=> <button> طباعة</button>}
     content={()=> componentRef.current}
     copyStyles={true}

    //  pageStyle={pageStyle}
/>
     
     
    </div>

   
    <div className='card-body p-9'  >

        
    {dataList && dataList.map((zitem:any, index:number) => {
                      return (
                        
              
                        <div className='row mb-7' key={index}>
                        <label className='col-lg-4 fw-bold text-muted'>
                        {zitem?.title}
                        </label>
                
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark'>
                          {data_object[zitem?.status_type]}
                          </span>
                        </div>
                      </div>
                  
                        );
                      })} 
                 
 
 
  

      
    </div>
    <div className='text-right'>
        
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-20 mb-3'
          onClick={(e) => respData(false)}
        >
          الغاء
        </button>
       
    </div>
  </div>

  )
}

export {ObjectInfo}
