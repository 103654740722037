/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams,useHistory} from 'react-router-dom' 
import { api_add, api_put, get_data, user_add } from '../api'
import { KTSVG } from '../../../../_metronic/helpers'
 
let initialValues = {
  companyName: '',
  contactName: '',
  contactTitle: '',
  Address: '',
  city: '',
  region: '',
  postalCode: '',
  country: '',
  phone: '',
  fax: '',
  customerId:''
}

const EditSeriesSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  contactName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
 
})

export function EditSeries() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const d=useHistory();
  
  const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: EditSeriesSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        api_put('Customers', values,params.id)
          .then(data => {
            debugger

            setLoading(false)
            d.goBack()

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('EditSeries process has broken')
          })
      }, 1000)
    },
  })
  useEffect(() => {

    if(params.id )
    {
 
     get_data('Customers/'+params.id )
     .then(data => {
       
       debugger 
       initialValues=data.data;
   formik.setValues(initialValues);
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
  return (
 
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>  edit  -
          {/* { params.id } */}

          <span className='card-label fw-bolder fs-3 mb-1'> 
          { params.id }
       
          </span>
          </span>
      
          <br/> 
          
        </h3>
        <div className='card-toolbar'>
       
        <Link to={"/crafted/surveys/series/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </Link>
          {/* <Link to="add">new </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */} 
          <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
 
 

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'> companyName</label>
          <input
            placeholder=' companyName'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.companyName && formik.errors.companyName,
              },
              {
                'is-valid': formik.touched.companyName && !formik.errors.companyName,
              }
            )}
          />
          {formik.touched.companyName && formik.errors.companyName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'> contactName</label>
            <input
              placeholder=' contactName'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('contactName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.contactName && formik.errors.contactName,
                },
                {
                  'is-valid': formik.touched.contactName && !formik.errors.contactName,
                }
              )}
            />
            {formik.touched.contactName && formik.errors.contactName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contactName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}
      <div className='row fv-row mb-7'>
<div className='col-xl-6'>
  <label className='class="form-label fw-bolder text-dark fs-6'> Address</label>
  <input
    placeholder=' Address'
    type='text'
    autoComplete='off'
    {...formik.getFieldProps('Address')}
    className={clsx(
      'form-control form-control-lg form-control-solid',
      {
        'is-invalid': formik.touched.Address && formik.errors.Address,
      },
      {
        'is-valid': formik.touched.Address && !formik.errors.Address,
      }
    )}
  />
  {formik.touched.Address && formik.errors.Address && (
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <span role='alert'>{formik.errors.Address}</span>
      </div>
    </div>
  )}
</div>
<div className='col-xl-6'>
  {/* begin::Form group Lastname */}
  <div className='fv-row mb-5'>
    <label className='form-label fw-bolder text-dark fs-6'> contactTitle</label>
    <input
      placeholder=' contactTitle'
      type='text'
      autoComplete='off'
      {...formik.getFieldProps('contactTitle')}
      className={clsx(
        'form-control form-control-lg form-control-solid',
        {
          'is-invalid': formik.touched.contactTitle && formik.errors.contactTitle,
        },
        {
          'is-valid': formik.touched.contactTitle && !formik.errors.contactTitle,
        }
      )}
    />
    {formik.touched.contactTitle && formik.errors.contactTitle && (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{formik.errors.contactTitle}</span>
        </div>
      </div>
    )}
  </div>
  {/* end::Form group */}
</div>
</div>

 
      {/* end::Form group */}
 
      <div className='row fv-row mb-7'>
<div className='col-xl-6'>
  <label className='class="form-label fw-bolder text-dark fs-6'> city</label>
  <input
    placeholder=' city'
    type='text'
    autoComplete='off'
    {...formik.getFieldProps('city')}
    className={clsx(
      'form-control form-control-lg form-control-solid',
      {
        'is-invalid': formik.touched.city && formik.errors.city,
      },
      {
        'is-valid': formik.touched.city && !formik.errors.city,
      }
    )}
  />
  {formik.touched.city && formik.errors.city && (
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <span role='alert'>{formik.errors.city}</span>
      </div>
    </div>
  )}
</div>
<div className='col-xl-6'>
  {/* begin::Form group Lastname */}
  <div className='fv-row mb-5'>
    <label className='form-label fw-bolder text-dark fs-6'> region</label>
    <input
      placeholder=' region'
      type='text'
      autoComplete='off'
      {...formik.getFieldProps('region')}
      className={clsx(
        'form-control form-control-lg form-control-solid',
        {
          'is-invalid': formik.touched.region && formik.errors.region,
        },
        {
          'is-valid': formik.touched.region && !formik.errors.region,
        }
      )}
    />
    {formik.touched.region && formik.errors.region && (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{formik.errors.region}</span>
        </div>
      </div>
    )}
  </div>
  {/* end::Form group */}
</div>
</div>
      {/* end::Form group */}
      <div className='row fv-row mb-7'>
<div className='col-xl-6'>
  <label className='class="form-label fw-bolder text-dark fs-6'> postalCode</label>
  <input
    placeholder=' postalCode'
    type='text'
    autoComplete='off'
    {...formik.getFieldProps('postalCode')}
    className={clsx(
      'form-control form-control-lg form-control-solid',
      {
        'is-invalid': formik.touched.postalCode && formik.errors.postalCode,
      },
      {
        'is-valid': formik.touched.postalCode && !formik.errors.postalCode,
      }
    )}
  />
  {formik.touched.postalCode && formik.errors.postalCode && (
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <span role='alert'>{formik.errors.postalCode}</span>
      </div>
    </div>
  )}
</div>
<div className='col-xl-6'>
  {/* begin::Form group Lastname */}
  <div className='fv-row mb-5'>
    <label className='form-label fw-bolder text-dark fs-6'> country</label>
    <input
      placeholder=' country'
      type='text'
      autoComplete='off'
      {...formik.getFieldProps('country')}
      className={clsx(
        'form-control form-control-lg form-control-solid',
        {
          'is-invalid': formik.touched.country && formik.errors.country,
        },
        {
          'is-valid': formik.touched.country && !formik.errors.country,
        }
      )}
    />
    {formik.touched.country && formik.errors.country && (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{formik.errors.country}</span>
        </div>
      </div>
    )}
  </div>
  {/* end::Form group */}
</div>
</div>
      {/* begin::Form group */}
      <div className='row fv-row mb-7'>
<div className='col-xl-6'>
  <label className='class="form-label fw-bolder text-dark fs-6'> phone</label>
  <input
    placeholder=' phone'
    type='text'
    autoComplete='off'
    {...formik.getFieldProps('phone')}
    className={clsx(
      'form-control form-control-lg form-control-solid',
      {
        'is-invalid': formik.touched.phone && formik.errors.phone,
      },
      {
        'is-valid': formik.touched.phone && !formik.errors.phone,
      }
    )}
  />
  {formik.touched.phone && formik.errors.phone && (
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <span role='alert'>{formik.errors.phone}</span>
      </div>
    </div>
  )}
</div>
<div className='col-xl-6'>
  {/* begin::Form group Lastname */}
  <div className='fv-row mb-5'>
    <label className='form-label fw-bolder text-dark fs-6'> fax</label>
    <input
      placeholder=' fax'
      type='text'
      autoComplete='off'
      {...formik.getFieldProps('fax')}
      className={clsx(
        'form-control form-control-lg form-control-solid',
        {
          'is-invalid': formik.touched.fax && formik.errors.fax,
        },
        {
          'is-valid': formik.touched.fax && !formik.errors.fax,
        }
      )}
    />
    {formik.touched.fax && formik.errors.fax && (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{formik.errors.fax}</span>
        </div>
      </div>
    )}
  </div>
  {/* end::Form group */}
</div>
</div>
      {/* end::Form group */}

   
     
      {/* begin::Form group */}
      <div className='text-left'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-20 mb-5'
          disabled={formik.isSubmitting || !formik.isValid  }
        >
          {!loading && <span className='indicator-label'>save</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/crafted/surveys/index'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-20 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}
    </div>

  )
}
