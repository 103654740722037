/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, FileUpload, get_data, user_add } from '../api'
import { KTSVG } from '../../../../_metronic/helpers'
import { Panel2 } from '../AddSurveys'
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}


const TransListSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseData:any;
  // Records per page to be shown & loaded
  id: number;

  data_ob: any;
  TransObject: any;
}

export const TransList = ({
  apiRoute,
  responseData,
  id ,
  data_ob,
  TransObject,
}: Props) => {
// class TransList extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState([] as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  const [dataListView, setListView] = useState([] as any);
  
 

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: TransListSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:'', 
          recipient_id:''
        }
        
      
        api_add( 'sam_role_update/'+TransObject.id+'/'+id, sat)
          .then((data:any)  => {
            

            if(data.data.status)
            {

              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
             
            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)

            }
            setLoading(false)
        responseData(false);

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('TransList process has broken')
          })
      }, 1000)
    },
  });
  const load_data = () => {
  
    if(id)
    {
 
     get_data('sam_role_list/'+ TransObject.id +'?id='+id+
    '&father_key='+id+
    '&recipient_id='+id )
     .then(data => {
       
       debugger
       setModel(data.data.list);  
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
  
  }
  useEffect(() => {
    if(TransObject.id )
    {
 
     get_data('sam_role_view/'+TransObject.id )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
      let d=data.data.list;
       
      if(d.length>0)
      {
      let data_list_view = d.slice(0,5); 
      setListView(data_list_view)
      }
      
       setLoading(false)
      load_data();

       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
   
  useEffect(() => {

 
   }, []);
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{Surveys?.title} 
            {/* { params.id } */}
         
            </span>
          <span className='card-label fw-bolder fs-3 mb-1'>    تعديل  بيانات </span> 

            <br/>
            {/* { params.id } */}
           
           </h3>
          <div className='card-toolbar'>
         
       
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>

          {showAlert && (
          
          <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
          <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
          <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
            <h5 className="mb-1">This is an alert</h5>
            <span>{status_massage}.</span>
          </div>
        
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
          </button>
        </div>
         
                )}

            {/* begin::Table */}
   
           
            <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>

              
              {dataListView && dataListView.map((plan:any, index:number) => {
                      return (
                        <th className='min-w-125px' key={index}>{plan.title}</th>

                        );
                      })}  
                
                <th className='min-w-150px'>action</th> 
                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
         

            {!model.length &&  (
                    <tr className="text-center">
                      <td colSpan={3}>No Data</td>
                    </tr>
                  )}
                
  
                      {model.map((item:any, index:number) => {
                      return (
                        <tr
                        key={index}
                        >
                              {dataListView && dataListView.map((zitem:any, index:number) => {
                      return (
                        
                        <td key={index}>
                        <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                        {item[zitem?.status_type]}
                        </span>
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Paid</span> */}
                      </td>  
                        );
                      })} 
                 
       
                 
                <td    >
                               
                                <Link to={`edit/${item.p_key}`}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
         
                                  </Link>
                                <button   className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm"  disabled={item.isactive}>
                                    
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />

                                </button>
        
  
      

  

{/* <span className='text-muted fw-bold text-muted d-block fs-7'>{item?.trans_key}</span>
<span className='text-muted fw-bold text-muted d-block fs-7'>{item?.trans_level}</span>
<span className='text-muted fw-bold text-muted d-block fs-7'>{item?.trans_time}</span>
             */}
                            </td>
                          
                        </tr>
                      )
                    })} 

           
 

             
            
             
            </tbody>
            {/* end::Table body */}
          </table>

            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default TransList;