/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {ErrorMessage, Field, FieldArray, Form,  Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 

import { api_add, FileUpload, get_data, user_add ,get_data_json} from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { FormSelect } from './FormSelect'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from './FileUploadServices'
import { FormObject } from './FormObject'
import { FormObjectItems } from './FormObjectItems'
import { FormObjectItemSales } from './FormObjectItemSales'

import { FormTabs } from './FormTabs'
import { interval } from 'rxjs'
let initialValues = {
  
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  is_delete:false,
  sam_chiled:[]
}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseDataArray:any;
  // Records per page to be shown & loaded
  id: number;
  dataList:any;
  id_index:number;
}

export const FormArray = ({
  apiRoute,
  responseDataArray,
  id ,
  dataList,
  id_index,
}: Props) => {
// class FormArray extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any); 
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false);
  const [showForm, setshowForm] = useState(false);
   
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  const [dataListAdd, setList] = useState([] as any);
  const [AddValue, setAddValue] = useState([] as any);
  const [AddValueEdit, setAddValueEdit] = useState([]);
  
  const [countData, setcountData] = useState(0); 
 
  const initialValues7 = {
    numberOfTickets: '',
    tickets: []
};
  
  const load_data = () => {
  
    if(params.id)
    {
     
     get_data('sam_role_edit_list/'+ id +'?id='+params.id)
     .then(data => {
       
        
       setAddValueEdit(data.data.data) 
       setshowForm(true)

       if(data.data.data)
       {
         setTimeout(() => {
          
        setcountData(2)
           
         }, 1000);
       }
    
      
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }else
    {
      setshowForm(true)

    }
  
  }


const validationSchema7 = Yup.object().shape({
     
  
});

const onSubmit7 =  (fields:any) => {
  // display form field values on success
 
  let res={
    id:id,
    id_index:id_index,
    role_type_id:id,
    sam:'array',
    total:AddValue.total, 

    data:JSON.stringify(AddValue),
    AddedDate:AddedDate,
  }
  responseDataArray(res)
}

const handleLgCloseObject = (event: any) =>
{

  if(event.data)
  {
     
    if(event.is_delete)
    {
      AddValue[event.id]=null; 
    }else
    {
      let t=0;
      let da=[];
        AddValue[event.id]=event; 
      AddValue.forEach((elemt:any) => {
         t=t+ elemt.total
        });
        let res={
          id:id,
          id_index:id_index,
          role_type_id:id,
          sam:'array',
          total:t,  
          data:JSON.stringify(AddValue),
          AddedDate:AddedDate,
        }
        responseDataArray(res)
    } 

  }
  

 
} 
 

const handleLgAdd = (e: any, values: any, setValues: any)=>
 {
  // update dynamic form
   
  const tickets = [...values.tickets];
  tickets.push({p_key:0,filed1:'',filed2:'',filed3:''});
   
  setValues({ ...values, tickets });
 
}

const handleLgAdd_array = (e: any, values: any, setValues: any)=>
 {
  // update dynamic form
   
  if(values.tickets.length==0&&AddValueEdit.length>0)
  {
    
    const tickets = [...values.tickets];
    AddValueEdit.forEach(element => {
    tickets.push(element);
        
      });
  
    setValues({ ...values, tickets });
  }

 
}
  useEffect(() => {
    if(id )
    {
      get_data_json('data_add_'+id)
    //  get_data('sam_role_add/'+id )
     .then(data => {
      
      setSurveys(data.data.data) ;
      setList(data.data.list) ; 
  load_data() 
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
  useEffect(() => {

 
   }, []);
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{Surveys?.title_root} 
            {/* { params.id } */}
         
            </span>
          <span className='card-label fw-bolder fs-3 mb-1'>       بيانات </span> 

            <br/>
            {/* { params.id } */}
           
           </h3>
          <div className='card-toolbar'>
          
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>

          {showAlert && (
          
          <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
          <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
          <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
            <h5 className="mb-1">This is an alert</h5>
            <span>{status_massage}.</span>
          </div>
        
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
          </button>
        </div>
         
                )}

            {/* begin::Table */}
            {showForm && (
          <Formik initialValues={initialValues7} validationSchema={validationSchema7} onSubmit={onSubmit7}>
            {({ errors, values, touched, setValues }) => (
              
                <Form>
                   
                 
               
            <a   className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={e => handleLgAdd_array(e, values, setValues)} 
            >
                <KTSVG path='/media/icons/duotune/art/art009.svg' className='svg-icon-3' />

                  </a>

              {/* {AddValueEdit && (
                    values.tickets=AddValueEdit
                  )} */}
                    <div className="card m-3" >
                        <h5 className="card-header">{Surveys?.title_root }    </h5>
                       
                        <FieldArray name="tickets">
                        {() => (values.tickets.map((ticket, i) => {
                            const ticketErrors = errors.tickets?.length && errors.tickets[i]  ;
                            const ticketTouched = touched.tickets?.length && touched.tickets[i]  ;
                            return (
                                <div key={i} className="list-group list-group-flush">
            {/* <FormObject key={i} apiRoute={`sam`} id={i}  dataList={dataListAdd}
                responseDataObject={handleLgCloseObject} ticket={ticket}/> */}
       {(Surveys?.titleen=='invoice_items' ||
        Surveys?.titleen=='invoice_reverse_items' ||
         Surveys?.titleen=='invoice_order_items')  && (
<FormObjectItemSales key={i} apiRoute={`sam`} id={i}  dataList={dataListAdd}
responseDataObject={handleLgCloseObject} ticket={ticket}/>
)}
       {(Surveys?.titleen=='bill_items' || 
       Surveys?.titleen=='bill_order_items' || 
       Surveys?.titleen=='bill_reverse_items')  && (
<FormObjectItems key={i} apiRoute={`sam`} id={i}  dataList={dataListAdd}

responseDataObject={handleLgCloseObject} ticket={ticket}/>
)} 
       {(Surveys?.titleen!='bill_items' && Surveys?.titleen!='invoice_items'
       && Surveys?.titleen!='invoice_reverse_items'
       && Surveys?.titleen!='invoice_order_items'
       && Surveys?.titleen!='bill_order_items'
       && Surveys?.titleen!='bill_reverse_items'
       )  && (
        <FormObject key={i} apiRoute={`sam`} id={i}  dataList={dataListAdd}

responseDataObject={handleLgCloseObject} ticket={ticket}/>
)}
                                
                                </div>
                            );
                        }))}
                        </FieldArray>
                        <div className="buttons">
            <button type="button" 
            className="btn btn-success mr-1"
            onClick={e => handleLgAdd(e, values, setValues)} 
            >

              اضافة جديد
            </button>
   
          </div>
                        {/* <div className="card-footer text-center border-top-0">
                            <button type="button" 
                              onClick={e => onSubmit7(values)} className="btn btn-primary mr-1">

                                Buy Tickets
                            </button>
                            <button className="btn btn-secondary mr-1" type="reset">Reset</button>
                        </div> */}
                    </div>
                </Form>
            )}
        </Formik>
           )}
            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
   
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default FormArray;