/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import { useCallback } from 'react';
// import DatePicker from "react-datepicker";

import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 
import 'react-modern-calendar-datepicker/lib/DatePicker.css'; 
import DatePicker, { DayValue, DayRange, Day } from 'react-modern-calendar-datepicker'


import { api_add, FileUpload, get_data, get_data_json, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { FormSelect } from './FormSelect'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from './FileUploadServices'
import { FormTabs } from './FormTabs' 
import { FormDateStart } from './FormDateStart'
const initialValues = {
  companyName: '',
  contactName: '',
  contactTitle: '',
  Address: '',
  city: '',
  region: '',
  postalCode: '',
  country: '',
  phone: '',
  fax: '',
  currency_code:'',
  filed2: '',
  sam_chiled:[]
}
 
const addSurveysSchema = Yup.object().shape({
  
 
 
})
export interface IPanelProps {
  txt?: string;
  data_ob: any;
  formik: any;
  data_index: number;
  
  value_object: any;
}
export interface IPanelDemoProps {
  panelName: string;
  data_ob: any;
}

export class PanelDemo2 extends Component<IPanelDemoProps, {}> {
  selectPanel = (panelName: string,data_ob:any) => {
    switch (panelName) {
      case "panel1":
        return <Panel2 data_ob={data_ob} data_index={1} formik={2} value_object={1}/>;

      case "panel2":
        return <Panel2 data_ob={data_ob} data_index={1} formik={2} value_object={1}/>;

      case "panel3":
        return <Panel2 data_ob={data_ob} data_index={1} formik={2} value_object={1} />;

      default:
        return <div>Unknown!</div>;
    }
  };
  render() {
    const { panelName } = this.props;
    const { data_ob } = this.props;
    return this.selectPanel(panelName,data_ob);
  }
}

export class Panel2 extends Component<IPanelProps, {}> {
  value_select: any[]=[];
  
  render() {
  
 

    const { txt } = this.props;
    const { data_ob } = this.props;
    const { data_index } = this.props;
    const { formik } = this.props;
    const { value_object } = this.props;
    
    // this.value_select=[];

    const load_data = () => {

     
      
      
      
    }
    const handleLgClose = (event: any) =>
    {
    
      
      formik.setFieldValue(event.key,event.value);
    } 
    const onChangetotal = (event: any) =>
    {
    
      
   let s=   formik.values.quantity;
   

 if(s&&event)
 {
  formik.setFieldValue('total',event*s);
  formik.setFieldValue('total7',event*s);

 }else if(event)
 {
  formik.setFieldValue('total',event*2);

 }
    } 
    // react-hooks/rules-of-hooks
    
  
    if (data_ob.ztype=='textfield')
    {
      return    <div  key={data_index} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='text'
        required={data_ob.zform}
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
      
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched[data_ob.status_type] && data_ob.zform && !formik.getFieldProps(data_ob.status_type).value,
            
          },
          {
         
             'is-valid': formik.touched[data_ob.status_type] &&  formik.getFieldProps(data_ob.status_type).value,
          }
        )}
      />
      
             {formik.touched[data_ob.status_type] && data_ob.zform &&  !formik.getFieldProps(data_ob.status_type).value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>
    }else if (data_ob.ztype=='phone')
    {
      return    <div  key={data_index} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='tel'
        required={data_ob.zform}
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
      
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched[data_ob.status_type] && data_ob.zform && !formik.getFieldProps(data_ob.status_type).value,
            
          },
          {
         
             'is-valid': formik.touched[data_ob.status_type] &&  formik.getFieldProps(data_ob.status_type).value,
          }
        )}
      />
      
             {formik.touched[data_ob.status_type] && data_ob.zform &&  !formik.getFieldProps(data_ob.status_type).value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>
    }else if (data_ob.ztype=='email')
    {
      return    <div  key={data_index} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='email'
        required={data_ob.zform}
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
      
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {
            'is-invalid': formik.touched[data_ob.status_type] && data_ob.zform && !formik.getFieldProps(data_ob.status_type).value,
            
          },
          {
         
             'is-valid': formik.touched[data_ob.status_type] &&  formik.getFieldProps(data_ob.status_type).value,
          }
        )}
      />
      
             {formik.touched[data_ob.status_type] && data_ob.zform &&  !formik.getFieldProps(data_ob.status_type).value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>  هذاء الحقل اجباري</span>
              </div>
            </div>
          )}
 
    </div>
    }else if (data_ob.ztype=='text'||data_ob.ztype=='textarea')
    {
      return    <div  key={data_index} className='col-xl-6'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <textarea
        placeholder={data_ob.title}
        value={formik.getFieldProps(data_ob.status_type).value}
     
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      >
       {formik.getFieldProps(data_ob.status_type).value}
      </textarea>
     
    </div>
    }else  if (data_ob.ztype=='password')
    {
      return  <div  key={data_ob} className='col-xl-4'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='password'
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='number')
    {
      return  <div  key={data_ob} className='col-xl-4'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
      
        placeholder={data_ob.title}
        type='number'
        pattern='[0-9]{0,5}'
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='price')
    {
      return  <div  key={data_ob} className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
            
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps('price')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
        onBlur={e => {
          // call the built-in handleBur
          onChangetotal(e.target.value)
          // and do something about e
          let someValue = e.currentTarget.value
           
      }}
      />
 
    </div>
    }else  if (data_ob.ztype=='quantity')
    {
      return  <div  key={data_ob} className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps('quantity')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='total')
    {
      return  <div  key={data_ob} className='col-xl-2'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        disabled={true}

        {...formik.getFieldProps('total')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }else  if (data_ob.ztype=='priority_key')
    {
      return  <div  key={data_ob} className='col-xl-4'  >
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        placeholder={data_ob.title}
        type='number'
        autoComplete='off'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
       
      />
 
    </div>
    }
     
    else  if (data_ob.ztype=='file')
    {
      
    
      return  <FileUploadServices key={data_index} apiRoute={`sam`}
      responseData={handleLgClose}
      id={1}
      data_ob={data_ob}
      data_survey={data_ob}
      data_index={8} />

    }else  if (data_ob.ztype=='datetime')
    {
      return    <FormDateStart key={data_index} apiRoute={`sam`}
      response7Data={handleLgClose}
      id={1}
      data_ob={data_ob}
      data_survey={data_ob}
      data_index={8} 
      formik={formik}/>

    }else  if (data_ob.ztype=='date')
    {
      return   <div  key={data_ob} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='date' 
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
 
    </div>
    }else  if (data_ob.ztype=='userupdate'&&data_ob.id==886)
    {
      return   <div className={`card mb-1 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>  
         بيانات تخص الهيئة
          
          </span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
        </h3>
        <div className='card-toolbar'>
          
     

     
        </div>
      </div>   
 
    </div>
    }else  if (data_ob.ztype=='time')
    {
      return   <div  key={data_ob} className='col-xl-4'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='time' 
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
 
    </div>
    }else  if (data_ob.ztype=='bit1')
    {
   

      return   <div  key={data_ob} className='col-xl-2'>
      <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input 
        type='checkbox'  
        value='1' 
        
        data-kt-check-target='.widget-13-check'
        {...formik.getFieldProps(data_ob.status_type)}
        className={clsx(
          'form-check-input', 
        )}
        onChange={(e) => {
          if(e.target.checked)
          {
            formik.setFieldValue(data_ob.status_type,1);

          }else{
            formik.setFieldValue(data_ob.status_type,0);

          }
 
          debugger
        }}
      />
 
    </div>
    }else if (data_ob.ztype=='select')
    {


      return  <FormSelect key={data_index} apiRoute={`sam`}
      response7Data={handleLgClose}
      id={1}
      data_ob={data_ob}
      data_survey={data_ob}
      data_index={8} 
      formik={formik}/>

       

    }else
    {
      return <div></div>
    }
    
  }
}

export function AddSurveys() {
  const d=useHistory();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const params = useParams<any>();
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
  const [showAlert, setShowAlert] = useState(false);
  const [ShowTotal, setShowTotal] = useState(false);
   
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  const [SurveyChildren, setSurveyChildren] = useState([] as any);
  const [AddValueTabs, setAddValue] = useState({} as any);
  

  const toggleShowAlert = () => setShowAlert(!showAlert);
  
  useEffect(() => {
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
 
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
      setSurveyChildren(data.data.children)
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
 
   }, []);
   const handleFormTabs = (event: any) =>
{

  if(event.data)
  {
    setAddValue(event)
    if(event.total)
    {
    formik.setFieldValue('total',event.total);

      setShowTotal(true)

    }

  }
  
 
} 
   const AddedDate=new Date().valueOf(); 
  const formik = useFormik({
    initialValues,
    validationSchema: addSurveysSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      
      setTimeout(() => {
         values.sam_chiled=[];
          
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:params.father_key,
          data_object:AddValueTabs, 
          recipient_id:null
        }
        
        api_add('sam_role_create/'+params.type, sat)
          .then((data:any) => {
            // 
            if(data.data.status)
            {
             
              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
            d.goBack()

            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)

            }
            setLoading(false) 
            // d.goBack()

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            // d.goBack()
            
            setSubmitting(false)
            setStatus('AddSurveys process has broken')
            setStatus_code('danger')
            setStatus_massag('AddSurveys process has broken')
          })
      }, 1000)
    },
  })

  // const handleKeyPress = useCallback((event) => {
  //   console.log(`Key pressed: ${event.key}`);
  //   if(event.key=='F9')
  //   {
  //     
  //     alert('Woooooooooooooo') ; 
  //   } 
  // }, []);

  // useEffect(() => {
  //   // attach the event listener
  //   document.addEventListener('keydown', handleKeyPress);

  //   // remove the event listener
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, [handleKeyPress]);

  // const onKeyPress = (event) => {
  //   console.log(`key pressed: ${event.key}`);
  // };

  // useKeyPress(['a', 'b', 'c'], onKeyPress);


  return (
   
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'> {Surveys?.title_root} </span> 
         
       
          <span className='card-label fw-bolder fs-3 mb-1'>    اضافة جديد </span> 
 
        </h3>
        <div className='card-toolbar'>
       
          <Link to={"/crafted/surveys/dashboard/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </Link>
          {/* <Link to="add">new </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table'>
       
        {showAlert && (
          
  <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
  <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
  <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
    <h5 className="mb-1">رسالة</h5>
    <span>{status_massage}.</span>
  </div>

  <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
    <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
  </button>
</div>



        )}
     



          {/* begin::Table */}
          <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
 
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
  
      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>


              
      {dataList && dataList.map((plan:any, index:number) => {
                      return (
                     
                    
            <Panel2 data_ob={plan} data_index={index} formik={formik} value_object={initialValues}/>
               
                        );
                      })} 
                


        
      </div>
      {/* {ShowTotal && (
         <div  className='col-xl-4'  >
         <label className='class="form-label fw-bolder text-dark fs-6'> total</label>
         <input 
           placeholder={'total'}
           type='number'
           disabled={true}
           autoComplete='off'
           {...formik.getFieldProps('total')}
           className={clsx(
             'form-control form-control-lg form-control-solid',
              
           )}
          
         />
    
       </div>
      )} */}
  
      <FormTabs   apiRoute={`sam`} id={1}  dataList={SurveyChildren}
                responseDataTabs={handleFormTabs}/>

      {/* end::Form group */}
  

 
      {/* end::Form group */}
 
 
      {/* <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!formik.isValid }
          // disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>حفظ</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
     
      </div> */}
      <div className='text-right' style={{float:"left"}}>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            disabled={!formik.isValid  }
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
          <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
            

            >
              الغاء
            </button>
        </div>
      {/* end::Form group */}
    </form>
      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}
    </div>

  )
}
