/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 

import { api_add, FileUpload, get_data, get_data_json, user_add } from '../api'
import { KTSVG } from '../../../../_metronic/helpers'
import { FormSelect } from '../FormSelect'
import { Button } from 'react-bootstrap-v5'
import { FileUploadServices } from '../FileUploadServices'
import { Panel2 } from '../AddSurveys'
 
const initialValues = {
  companyName: '',
  contactName: '',
  contactTitle: '',
  Address: '',
  city: '',
  region: '',
  postalCode: '',
  country: '',
  phone: '',
  fax: '',
  filed2: '',
  sam_chiled:[]
}
 
const AddSeriesSchema = Yup.object().shape({
  
 
 
})
export interface IPanelProps {
  txt?: string;
  data_ob: any;
  formik: any;
  data_index: number;
  

}
export interface IPanelDemoProps {
  panelName: string;
  data_ob: any;
}


export function AddSeries() {
  const d=useHistory();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const params = useParams<any>();
  const [Surveys, setSurveys] = useState({} as any);
  const [dataList, setList] = useState([] as any);
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
  const toggleShowAlert = () => setShowAlert(!showAlert);
  
  useEffect(() => {
    if(params.type )
    {
      get_data_json('data_add_'+params.type )
    //  get_data('sam_role_add/'+params.type )
     .then(data => {
      setSurveys(data.data.data) ;
      setList(data.data.list) ;
        
  debugger
      //  setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
      //  setLoading(false)
    
     })
    }
 
   }, []);
   
   const AddedDate=new Date().valueOf(); 
  const formik = useFormik({
    initialValues,
    validationSchema: AddSeriesSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      debugger
      setTimeout(() => {
         values.sam_chiled=[];
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:null, 
          recipient_id:null
        }
        api_add('sam_role_create/'+params.type, sat)
          .then((data:any) => {
            debugger
            if(data.data.status)
            {
             
              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
            d.goBack()

            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)

            }
            setLoading(false) 
            // d.goBack()

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            // d.goBack()
            
            setSubmitting(false)
            setStatus('AddSeries process has broken')
            setStatus_code('danger')
            setStatus_massag('AddSeries process has broken')
          })
      }, 1000)
    },
  })

 
  return (
   
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'> {Surveys?.title_root} </span> 
         
       
          <span className='card-label fw-bolder fs-3 mb-1'>    اضافة جديد </span> 
 
        </h3>
        <div className='card-toolbar'>
       
          <Link to={"/crafted/surveys/series/"+params.type} className='btn btn-back btn-light-primary'> <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
           رجوع </Link>
          {/* <Link to="add">new </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
       
        {showAlert && (
          
  <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
  <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
  <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
    <h5 className="mb-1">This is an alert</h5>
    <span>{status_massage}.</span>
  </div>

  <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
    <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
  </button>
</div>



        )}
     



          {/* begin::Table */}
          <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
 
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
  
      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>


              
      {dataList && dataList.map((plan:any, index:number) => {
                      return (
                     
                    
            <Panel2 data_ob={plan} data_index={index} formik={formik} value_object={initialValues}/>
               
                        );
                      })} 
                


        
      </div>
      {/* end::Form group */}
  

 
      {/* end::Form group */}
 
      {/* <div className='text-right' style={{float:"left"}}>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-0 mb-3'
            disabled={!formik.isValid  }
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
          <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
            

            >
              الغاء
            </button>
        </div> */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!formik.isValid }
          // disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>حفظ</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              الرجاء الانتظار ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
     
      </div>
      {/* end::Form group */}
    </form>
      
          {/* end::Table */}
          {/* {paginationBasic} */}
  

        </div>
        
       
        {/* end::Table container */}
   
          <div>


    
          </div>
  

      </div>
      {/* begin::Body */}
    </div>

  )
}
