/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, FileUpload, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
let initialValues = {
  p_key: 0, 
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  total:0,
  currency_code:'',
  is_delete:false,

  sam_chiled:[]


}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseDataObject:any;
  // Records per page to be shown & loaded
  id: number;
  dataList:any;
  ticket:any;
}

export const FormObject = ({
  apiRoute,
  responseDataObject,
  id ,
  dataList,
  ticket,
}: Props) => {
// class FormObject extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any); 
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  
 
  initialValues=ticket;
  //  formik.setValues(initialValues);

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:values,
          AddedDate:AddedDate,
          father_key:'',
          data_object:'', 
          id:id, 
          recipient_id:''
        } 
         
        // responseDataObject(sat);
       
      }, 1000)
    },
  });
 
  const save_data = (event: any) => {
    debugger
  let values=formik.values;
    values.sam_chiled=[];
        
     
    let sat={
      data:values,
      AddedDate:AddedDate,
      father_key:'',
      data_object:'', 
      id:id, 
      total:values.total, 
      delete:false, 
      
      recipient_id:''
    } 
     
    responseDataObject(sat);
  
  }
  useEffect(() => {
    if(ticket.p_key)
    { 
      initialValues=ticket;
      formik.setValues(initialValues);
    }
 
   }, []);
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{Surveys?.title_root} 
            {/* { params.id } */}
         
            </span>
          <span className='card-label fw-bolder fs-3 mb-1'>      </span> 

            <br/>
            {/* { params.id } */}
           
           </h3>
     
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>

          {showAlert && (
          
          <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
          <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
          <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
            <h5 className="mb-1">This is an alert</h5>
            <span>{status_massage}.</span>
          </div>
        
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
          </button>
        </div>
         
                )}

            {/* begin::Table */}
   
            <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      
      >
        {/* begin::Heading */}
      
        {/* end::Heading */}
  
        {/* begin::Action */}
      
        {/* end::Action */}
 
  
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
  
    
      {/* begin::Form group Firstname */}
  
      <div className='row fv-row mb-7'>
  
{dataList && dataList.map((plan:any, index:number) => {
                return (
                
      <Panel2 data_ob={plan} data_index={index} formik={formik} value_object={initialValues}/>
         
                  );
                })} 
          


  
</div>
 

        {/* end::Form group */}
   
      
        {/* end::Form group */}
        
        {/* begin::Form group */}
        <div className='text-left'>
          <button
            type='button'
            onClick={save_data} 
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-20 mb-3'
            disabled={!formik.isValid  }
          >
            {!loading && <span className='indicator-label'>حفظ</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                الرجاء الانتظار ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
            {/* <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
             

            >
              الغاء
            </button> */}
         
        </div>
        {/* end::Form group */}
      </form>
        
            {/* end::Table */}
            {/* {paginationBasic} */}
    
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default FormObject;