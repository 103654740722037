/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, FileUpload, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { FormArray } from './FormArray'
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseDataTabs:any;
  // Records per page to be shown & loaded
  id: number;
  dataList:any;

}

export const FormTabs = ({
  apiRoute,
  responseDataTabs,
  id ,
  dataList,
}: Props) => {
// class FormTabs extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any); 
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf();  
  const [showAlert, setShowAlert] = useState(false); 
  const [status_code, setStatus_code] = useState('primary'); 
  const [status_massage, setStatus_massag] = useState('done'); 
  const [AddValueArray, setAddValue] = useState([] as any);
  
 

 
  // const params = useParams<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        values.sam_chiled=[];
        
        let sat={
          data:JSON.stringify(values),
          AddedDate:AddedDate,
          father_key:'',
          data_object:'', 
          recipient_id:''
        }
        setShowAlert(false)
        
      
        api_add( 'sam_role_update/'+params.type+'/'+id, sat)
          .then((data:any)  => {
            

            if(data.data.status)
            {

              setStatus_code('success')
              setStatus_massag('تمت العملية بنجاح')
              setShowAlert(true)
              setLoading(false)
              responseDataTabs(false);
            }else
            {
              setStatus_code('danger')
              setStatus_massag(data.data.message)
              setShowAlert(true)
              setLoading(false)


            }
    

            // dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('FormTabs process has broken')
          })
      }, 1000)
    },
  });
  const load_data = () => {
  
    if(id)
    {
 
     get_data('sam_role_edit/'+ params.type +'?id='+id)
     .then(data => {
       
       debugger
       setModel(data.data.data);
       initialValues=data.data.data;
   formik.setValues(initialValues);
       setLoading(false)
       // dispatch(auth.actions.login(accessToken))
     })
     .catch(() => {
       setLoading(false)
    
     })
    }
  
  }
  const handleFormArray = (event: any) =>
  {
  
    
    if(event.data)
    {
      debugger
      AddValueArray[event.id_index]=event;
let t=0;
      AddValueArray.forEach((elemt:any) => {
        t=t+ elemt.total
       });
      let res={
        id:id,
        role_type_id:id,
        sam:'tabs', 
        data:JSON.stringify(AddValueArray),
        total:t,
        AddedDate:AddedDate,
      }
      responseDataTabs(res);

    }
  } 
  const handleFormArrayRes = () =>
  {
  
    
   
      let res={
        id:id,
        role_type_id:id,
        sam:'tabs',
        data:JSON.stringify(AddValueArray),
        AddedDate:AddedDate,
      }

  } 
 
  
  useEffect(() => {

 
   }, []);
    return (
   
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{Surveys?.title_root} 
            {/* { params.id } */}
         
            </span>
          <span className='card-label fw-bolder fs-3 mb-1'>   </span> 

            <br/>
            {/* { params.id } */}
           
           </h3>
          <div className='card-toolbar'>
         
       
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>

          {showAlert && (
          
          <div className={`alert alert-dismissible bg-light-${status_code} border border-${status_code} d-flex flex-column flex-sm-row p-5 mb-10`}>
          <span className={`svg-icon svg-icon-2hx svg-icon-${status_code} me-4 mb-5 mb-sm-0`}>...</span>
          <div className={`d-flex flex-column text-${status_code} pe-0 pe-sm-10`}>
            <h5 className="mb-1">This is an alert</h5>
            <span>{status_massage}.</span>
          </div>
        
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <span className={`svg-icon svg-icon-1 svg-icon-${status_code}`}>...</span>
          </button>
        </div>
         
                )}

            {/* begin::Table */}
    
            {/* end::Table */}
            {/* {paginationBasic} */}
     


<div>
<div>
  {dataList.length==1 && dataList.map((plan:any, index:number) => {
                return (
                  <div  >
    
    حركة 2

    <FormArray  key={index} apiRoute={`sam`} id={plan.root_id} id_index={index}  dataList={plan}
                responseDataArray={handleFormArray}/>

                  </div>
      
                  );
                })} 
  </div>
    
  <div>
    
  {dataList.length>1 && (
<>
<ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
  
  {dataList && dataList.map((plan:any, index:number) => {
              return (
                <li  key={index} className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href={`#kt_tab_pane_`+index+2} 
                >
            2     قائمة
                </a>
              </li>
    
                );
              })} 

</ul>
<div className="tab-content" id="myTabContent">

<div
  className="tab-pane fade active show"
  id="kt_tab_pane_1"
  role="tabpanel"
>
  حركة العمليات

</div>

{dataList && dataList.map((plan:any, index:number) => {
            return (
              <div className="tab-pane fade" id={`kt_tab_pane_`+index+2} role="tabpanel">

حركة 2

<FormArray  key={index} apiRoute={`sam`} id={plan.root_id} id_index={index}  dataList={plan}
            responseDataArray={handleFormArray}/>

              </div>
  
              );
            })} 



</div>
</>

                )}

  </div>

  <div className='text-right'>
        
 
        </div>
    </div>
  
          </div>
          
         
          {/* end::Table container */}
     
            <div>
  
  
      
            </div>
    
  
        </div>
        {/* begin::Body */}
      </div>
  
    )
    
  
}

// export default FormTabs;