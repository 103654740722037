/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, RouteComponentProps, useHistory} from 'react-router-dom' 
import { useParams} from 'react-router-dom' 
 
import { Button } from 'react-bootstrap-v5' 
import { Filter } from '../Filter'
import { TransUpdate } from './transUpdate'
import { TransAdd } from './transAdd'
import { TransList } from './transList'
export interface IPanelDemoProps {
    panelName: string;
    data_ob: any;
    Surveys: any;
    RespFilter_data: any;
  }
export class TransServices extends Component<IPanelDemoProps, {}> {

    
    selectPanel = (panelName: string,data_ob:any,Surveys:any,RespFilter_data:any) => {
      switch (panelName) {
        case "panel1":

          return <Filter apiRoute=''
          dataObject={Surveys}
          responseData={RespFilter_data}
          dataList={Surveys?.sam_search}
           />;
  
        case "update":
            return <TransUpdate apiRoute=''
            data_ob={data_ob}
            id={data_ob.p_key}
            responseData={RespFilter_data}
            TransObject={Surveys}
             />;
        case "add":
             return <TransAdd apiRoute=''
            data_ob={data_ob}
            id={data_ob.p_key}
            responseData={RespFilter_data}
            TransObject={Surveys}
             />;
             case "list":
                return <TransList apiRoute=''
               data_ob={data_ob}
               id={data_ob.p_key}
               responseData={RespFilter_data}
               TransObject={Surveys}
                />;
              
        default:
          return <div>Unknown!</div>;
      }
    };
    render() {
      const { panelName } = this.props;
      const { data_ob } = this.props;
      const { Surveys } = this.props; 
      const { RespFilter_data } = this.props; 
   
      return this.selectPanel(panelName,data_ob,Surveys,RespFilter_data);
    }
  }