export interface Grievances_model {





 
  id: number
  contact_id: number
  number_cash: string
  number_cash_note: string 
  number_parallel: number 
  amount_cash: number
  note: string
}




export let initialValues = {
  sam_chiled:[],
  tickets:[],
  AddedDate:0,
fatherkey:null,
data_object:null, 
  order_by:'',
  quantity:0,
  order_how:'',
recipient_id:null,
  





  number_cash: '', 
  number_cash_note: '', 
  number_parallel: 0, 
  amount_cash: 0, 
  date_at: null, 
  note: '', 
}



export let initialValues_list = [{
  sam_chiled:[],
  tickets:[],
  AddedDate:0,
fatherkey:null,
data_object:null, 
  order_by:'',
  quantity:0,
  order_how:'',
recipient_id:null, 
  number_cash: '', 
  number_cash_note: '', 
  number_parallel: 0, 
  amount_cash: 0, 
  date_at: null, 
  note: '', 
}];
