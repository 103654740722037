/* eslint-disable jsx-a11y/anchor-is-valid */
import { time } from 'console'
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'
import { ChartList } from './services/chart_line'
 
type Props = {
  className: string,
  data: any,
  respData:any,
}

const ListsInfo: React.FC<Props> = ({className,data,respData}) => {
  debugger
  return (
    <div>
       <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
    <li className="nav-item">
      <a
        className="nav-link active"
        data-bs-toggle="tab"
        href="#kt_tab_pane_1"
      >
      حركة العمليات
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#kt_tab_pane_2"
      >
       قائمة
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-bs-toggle="tab"
        href="#kt_tab_pane_3"
      >
    مخطط العمليات

      </a>
    </li>
  </ul>
  <div className="tab-content" id="myTabContent">
    <div
      className="tab-pane fade active show"
      id="kt_tab_pane_1"
      role="tabpanel"
    >
     <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>حركة العمليات </span>
 
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bolder text-gray-800 fs-6'>08:00</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-warning fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='fw-mormal timeline-content text-muted ps-3'>
            start
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}

           {data && data.map((zitem:any, index:number) => {
                      return ( 
                        <div className='timeline-item' key={index}>
            {/* begin::Label */}
            <div className='timeline-label fw-bolder text-gray-800 fs-7'> 
            {zitem?.time_trans }</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
            
              <i className={`fa fa-genderless text-${zitem.status_code} fs-1 `}></i>
            </div>
            {/* end::Badge */}
            {/* begin::Content */}
            <div className='timeline-content d-flex'>
            <span className='text-muted fw-bold text-muted d-block fs-7'>{zitem?.structure_name}</span>

              <span className='fw-bolder text-gray-800 ps-3'> 
              {zitem?.name} </span>
             


            </div>
            {/* end::Content */}
          </div>

                  
                        );
                      })} 
                 
       
          {/* end::Item */}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
    </div>
    <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>حركة العمليات</span> 
        </h3>
       
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                  <th className='min-w-125px'>الادارة</th>
                <th className='min-w-150px'>الحالة</th> 
                <th className='min-w-150px'>المدة</th> 

                <th className='min-w-125px'>التاريخ</th> 
                <th className='min-w-150px'>ملاحضة</th> 
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
           
              {data && data.map((zitem:any, index:number) => {
                      return (
                        
              
                        <tr key={index}>
 
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.structure_name}
                          </span>
                         </td>
                         <td>  
                          <span className={`badge badge-light-${zitem.status_code} fs-7 fw-bold`}>{zitem.status_name}</span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.useradded} ساعة
                          </span>
                         </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.date_in}
                          </span>
                           </td>
                           
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {zitem.note}
                          </span>
 
                        </td>
                    
                   
                      </tr>

                  
                        );
                      })} 
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    </div>
    <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
    <ChartList
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
          dataValue={data}
            />
    </div>
  </div>
  <div className='text-right'>
        
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-20 mb-3'
              onClick={(e) => respData(false)}
            >
              الغاء
            </button> 
        </div>
    </div>
  
  )
}

export {ListsInfo}
