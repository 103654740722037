/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import { useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx' 
import {Link, useParams} from 'react-router-dom' 
import { api_add, api_put, FileUpload, get_data, user_add } from './api'
import { KTSVG } from '../../../_metronic/helpers'
import { Panel2 } from './AddSurveys'
import { Form } from 'react-bootstrap-v5'
let initialValues = {
  companyName: '', 
  filed2: '',
  filed3: '',
  filed4: '',
  filed5: '',
  sam_chiled:[]


}


const EditSchema = Yup.object().shape({
 
})

interface Props {
  // Url route of API, example: /api/students
  apiRoute: string;
  responseData:any;
  // Records per page to be shown & loaded
  id: number;
  data_ob: any; 
  data_survey: any; 
  data_index: number;
}

export const FileUploadServices = ({
  apiRoute,
  responseData,
  id ,
  data_ob, 
  data_survey,
  data_index,
}: Props) => {
// class FileUploadServices extends React.Component {
   
  
    
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState({} as any)
  const [Surveys, setSurveys] = useState({} as any);
  
  const [dataList, setList] = useState([] as any);
  const [value_select, SetValueSelect] = useState([] as any);
  const params = useParams<any>();
  const dispatch = useDispatch()
  const AddedDate=new Date().valueOf(); 
  const [recordsPerPage, setRecordsPerPage] = useState(1); 

 
 

  const [imgUrl, setimgUrl] = useState([] as any);
  const [selectedFile, setSelectedFile] = useState({} as any)

  // handle change event of input file
 
  const onChangeFile = (event:any) => {
    setSelectedFile(event.target.files[0])
  };

   
  const handleUpload = (event:any) => {
    FileUpload(selectedFile).then(response => {
    
       
      setimgUrl(response.data.url)
   
      let res={value:response.data.SamUrl,
        key:data_ob.status_type
        }
     
        responseData(res)
        // imageUrl: BASE_URL + response.data.file.path
      
    })
    .catch(err => {
      alert(err.message);
    });
  };
   
    return (
 
   
      <div className="App"  key={data_ob}>
         
          <p className="title">اختار الملف:</p>
          <label className='class="form-label fw-bolder text-dark fs-6'> {data_ob.title}</label>
      <input
        placeholder={data_ob.title}
        type='file'
        onChange={onChangeFile}
        autoComplete='off'
        name='file'
        className={clsx(
          'form-control form-control-lg form-control-solid',
           
        )}
      />
          <input type="button" value="Upload" onClick={handleUpload} />
      

          <p className="title" style={{ marginTop: 30 }}>
            رفع الملف:
          </p>
          {imgUrl && (
            <img src={imgUrl} alt="Uploaded File" height="100" width="100" />
          )}
        </div>
         
    
    )
    
  
}

// export default FileUploadServices;

